import React, {Suspense, useState} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import SessionService from '../service/SessionService';
import Response from '../model/Response';
import Translator from '../translations/Translator';
import Required from '../shared/Required';

function PageLogin() {
    const [email, setEmail] = useState('');
    const handleEmail = (event: { target: { value: any } }) => {
        setEmail(event.target.value);
    };

    const [password, setPassword] = useState('');
    const handlePassword = (event: { target: { value: any } }) => {
        setPassword(event.target.value);
    };

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const login = () => {
        console.debug('logging in with ' + email + ' and ' + password);
        var query = process.env.REACT_APP_API_URL + '/auth?email=' + email + '&password=' + password;
        console.debug(query);
        fetch(query, {
            method: 'POST'
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    if (res.error == 'i18n.unpaid') {
                        //Go to recheckout
                        window.location.replace('/checkout?msg=i18n.unpaid&email=' + email);
                    } else if (res.error == 'i18n.missing.payment.details') {
                        //Go to checkout
                        window.location.replace('/checkout?msg=i18n.missing.payment.details&email=' + email);
                    } else {
                        setSuccess('');
                        setError(res.error + Response.support + res.id);
                    }
                } else {
                    SessionService.login(res);
                    window.location.replace('/dashboard/products');
                }
            })
            .catch((error) => {
                console.debug(error);
                SessionService.logout();
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Login - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div>
                        <div className="mb-4">
                            <Translator value="i18n.email.address" />
                            <Required />
                            <input onChange={handleEmail} type="email" className="form-control form-control-lg" name="email" id="signupSimpleLoginEmail" aria-label="email@site.com" />
                        </div>
                        <div className="mb-4">
                            <Translator value="i18n.password" />
                            <Required />
                            <div className="input-group input-group-merge" data-hs-validation-validate-class="">
                                <input
                                    onChange={handlePassword}
                                    type="password"
                                    className="js-toggle-password form-control form-control-lg"
                                    name="password"
                                    id="signupSimpleLoginPassword"
                                    aria-label="8+ characters required"
                                    data-hs-toggle-password-options='{
                     "target": "#changePassTarget",
                     "defaultClass": "bi-eye-slash",
                     "showClass": "bi-eye",
                     "classChangeTarget": "#changePassIcon"
                   }'
                                />
                                <a id="changePassTarget" className="input-group-append input-group-text" href="javascript:;">
                                    <i id="changePassIcon" className="bi-eye-slash"></i>
                                </a>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <a className="form-label-link" href="/recover">
                                    <Translator value="i18n.forgot.password" />
                                </a>
                            </div>
                        </div>

                        <div className="d-grid mb-3">
                            <button onClick={login} className="form-control btn btn-secondary btn-lg">
                                <Translator value="i18n.login" />
                            </button>
                        </div>

                        <div className="text-center">
                            <p>
                                <Translator value="i18n.dont.have.an.account.yet" />{' '}
                                <a className="link" href="/register">
                                    <Translator value="i18n.signup.here" />
                                </a>
                            </p>
                        </div>

                        {Response.html(success, error)}
                    </div>
                </div>
                <div className="col-md-4"></div>

                <Footer />
            </div>
        </Suspense>
    );
}

export default PageLogin;
