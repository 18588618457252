import React from 'react';
import RuleDTO from '../../model/RuleDTO';
import RowRule from '../row/RowRule';
import Translator from '../../translations/Translator';

function TableRules(props: { data: Array<RuleDTO> }) {
    // @ts-ignore
    return (
        <table className="table table-striped table-hover table-dark table-sm table-responsive-sm">
            <thead>
                <tr>
                    <th scope="col" className="col-3">
                        <Translator value="i18n.name" />
                    </th>
                    <th scope="col" className="col-2">
                        <Translator value="i18n.company" />
                    </th>
                    <th scope="col" className="col-3">
                        <Translator value="i18n.subscriptions" />
                    </th>
                    <th scope="col" className="col-2">
                        <Translator value="i18n.trigger" />
                    </th>
                    <th scope="col" className="col-1"></th>
                    <th scope="col" className="col-1"></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowRule rule={item} />
                ))}
            </tbody>
        </table>
    );
}

export default TableRules;
