import React from 'react';
import ButtonDelete from '../button/ButtonDelete';
import SaleItemDTO from '../../model/SaleItemDTO';

function RowSaleItem(props: { saleItem: SaleItemDTO }) {
    return (
        <div>
            <div className='row'>
                <div className='col-sm-6'>
                    <input className='form-control' value={props.saleItem.name} disabled />
                </div>
                <div className='col-sm-4'>
                    <input className='form-control' value={props.saleItem.quantity} disabled />
                </div>
                <div className='col-sm-2'>
                    <ButtonDelete id={props.saleItem.id} type='sale/item' />
                </div>
            </div>
            <br />
        </div>


    );
}

export default RowSaleItem;
