class PageDTO {
    totalPages!: number;
    totalElements!: number;
    first!: boolean;
    last!: boolean;
    numberOfElements!: number;
    size!: number;
    content!: any;
    number!: number;
    empty!: boolean;
    numbers!: number[];

    constructor(){
        this.content=[];
    }
}

export default PageDTO;
