import React, {Suspense, useEffect, useState} from 'react';
import DashboardLeft from '../../shared/DashboardLeft';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import ButtonDelete from '../button/ButtonDelete';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';
import Translator from '../../translations/Translator';
import Required from '../../shared/Required';
import Line from '../../shared/Line';

function PageProfile() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [company_address, setCompanyAddress] = useState('');
    const [company_description, setCompanyDescription] = useState('');
    const [company_receipt_number, setCompanyReceiptNumber] = useState('');
    const [company_contact_number, setCompanyContactNumber] = useState('');
    const [userId, setUserId] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleName = (event: { target: { value: any } }) => {
        setName(event.target.value);
    };

    const handleCompanyName = (event: { target: { value: any } }) => {
        setCompanyName(event.target.value);
    };

    const handleCompanyAddress = (event: { target: { value: any } }) => {
        setCompanyAddress(event.target.value);
    };

    const handleCompanyDescription = (event: { target: { value: any } }) => {
        setCompanyDescription(event.target.value);
    };

    const handleCompanyReceiptNumber = (event: { target: { value: any } }) => {
        setCompanyReceiptNumber(event.target.value);
    };

    const handleCompanyContactNumber = (event: { target: { value: any } }) => {
        setCompanyContactNumber(event.target.value);
    };

    useEffect(() => {
        const id = SessionService.read(SessionService.userIdKey);
        if (id != null) {
            console.debug('getting user');
            setUserId(id);
            var query;
            query = process.env.REACT_APP_API_URL + '/v1/user?id=' + id;
            console.debug(query);
            fetch(query, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
                }
            })
                .then((res) => res.json())
                .then((res) => {
                    console.debug(res);
                    if (res.hasOwnProperty('error')) {
                        setSuccess('');
                        setError(res.error + Response.support + res.id);
                    } else {
                        setName(res.name);
                        setEmail(res.email);
                    }
                })
                .catch((error) => {
                    console.debug(error);
                    setError(Response.i18n['error-unknown']);
                    setSuccess('');
                });
        }

        const company_id = SessionService.read(SessionService.companyIdKey);
        if (company_id != null) {
            console.debug('getting company');
            var query;
            query = process.env.REACT_APP_API_URL + '/v1/company?id=' + company_id;
            console.debug(query);
            fetch(query, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
                }
            })
                .then((res) => res.json())
                .then((res) => {
                    console.debug(res);
                    if (res.hasOwnProperty('error')) {
                        setSuccess('');
                        setError(res.error + Response.support + res.id);
                    } else {
                        if (res.name != undefined) {
                            setCompanyName(res.name);
                        }
                        if (res.address != undefined) {
                            setCompanyAddress(res.address);
                        }
                        if (res.contactNumbers != undefined) {
                            setCompanyContactNumber(res.contactNumbers);
                        }
                        if (res.receiptNumber != undefined) {
                            setCompanyReceiptNumber(res.receiptNumber);
                        }
                        if (res.description != undefined) {
                            setCompanyDescription(res.description);
                        }
                    }
                })
                .catch((error) => {
                    console.debug(error);
                    setError(Response.i18n['error-unknown']);
                    setSuccess('');
                });
        }
    }, []);

    const saveUser = () => {
        console.debug('saving user');
        var query;
        query = process.env.REACT_APP_API_URL + '/v1/user?id=' + SessionService.read(SessionService.userIdKey) + '&name=' + encodeURIComponent(name);
        console.debug(query);
        fetch(query, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + '34509');
                } else {
                    setSuccess(Response.i18n['success-saved']);
                    setError('');
                    setName(res.name);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };

    const saveCompany = () => {
        console.debug('saving user');
        var query;
        query =
            process.env.REACT_APP_API_URL +
            '/v1/company?id=' +
            SessionService.read(SessionService.companyIdKey) +
            '&address=' +
            encodeURIComponent(company_address) +
            '&contact_numbers=' +
            encodeURIComponent(company_contact_number) +
            '&description=' +
            encodeURIComponent(company_description) +
            '&name=' +
            encodeURIComponent(company_name) +
            '&receipt_numbers=' +
            encodeURIComponent(company_receipt_number);
        console.debug(query);
        fetch(query, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-saved']);
                    setError('');
                    setName(res.name);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Profile - zizier';
    // @ts-ignore
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <DashboardLeft />
                <div className="col-md-9">
                    <div className="container-md">
                        {Response.html(success, error)}
                        <h5>
                            <Translator value="i18n.user.information" />
                        </h5>
                        <Line />
                        <div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.full.name" />
                                    <Required />
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" value={name} onChange={handleName} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.email" />
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" disabled value={email} />
                                </div>
                            </div>
                            <button onClick={saveUser} className="form-control btn btn-secondary">
                                <Translator value="i18n.save.user" />
                            </button>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="container-md">
                        <h5>
                            <Translator value="i18n.company.information" />
                        </h5>
                        <Line />
                        <div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.name" />
                                    <Required />
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" value={company_name} onChange={handleCompanyName} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.address" />
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" value={company_address} onChange={handleCompanyAddress} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.description" />
                                </label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" rows={3} value={company_description} onChange={handleCompanyDescription}></textarea>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.receipt.phone.number" /> :
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="tel" value={company_receipt_number} onChange={handleCompanyReceiptNumber} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.contact.phone.number" /> :
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="tel" value={company_contact_number} onChange={handleCompanyContactNumber} />
                                </div>
                            </div>
                            <button onClick={saveCompany} className="form-control btn btn-secondary">
                                <Translator value="i18n.save.company" />
                            </button>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="container-md">
                        <h5>
                            <Translator value="i18n.dangerous.area" />
                        </h5>
                        <Line />
                        <div className="mb-3 row">
                            <label className="col-sm-11 form-label">
                                <Translator value="i18n.delete.this.account" /> :
                            </label>
                            <div className="col-sm-1">
                                <ButtonDelete id={userId} type="user/account" />
                            </div>
                        </div>

                        {Response.html(success, error)}
                    </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageProfile;
