import React from 'react';
import Translator from '../../translations/Translator';

function ButtonNavBlue(props: { value: string; action: string }) {
    return (
        <div className="d-grid mb-3">
            <a href={props.action} className="row no-decoration">
                <button type="submit" className="btn btn-secondary btn-sm">
                    <Translator value={props.value}></Translator>
                </button>
            </a>
        </div>
    );
}

export default ButtonNavBlue;
