import React from 'react';
import ButtonDelete from '../button/ButtonDelete';
import InvitationDTO from '../../model/InvitationDTO';
import DateService from '../../service/DateService';

function RowInvitationSent(props: { invitation: InvitationDTO }) {
    return (
        <tr>
             <td>{DateService.toDate(props.invitation.createdTime)}</td>
            <td>{props.invitation.receiverEmail}</td>
            <td>
                <ButtonDelete id={props.invitation.id} type='invitation' />
            </td>
        </tr>
    );
}

export default RowInvitationSent;
