import React from 'react';
import messages_fr from '../translations/fr.json';
import messages_en from '../translations/en.json';

class Response {
    static i18n = {
        'error-cannot-delete': 'i18n.cannot.delete',
        'error-unknown': 'i18n.unknown.error',
        'error-passwords-different': 'i18n.passwords.different',
        'success-email-sent': 'i18n.email.sent',
        'success-account-created': 'i18n.account.created',
        'success-account-edited': 'i18n.account.edited',
        'success-invitation-accepted': 'i18n.invitation.accepted',
        'success-invitation-rejected': 'i18n.invitation.rejected',
        'success-password-changed': 'i18n.password.changed',
        'recover-register': 'i18n.email.sent',
        'success-saved': 'i18n.saved'
    };

    static support = ' | ';

    private static isError(success: string, error: string) {
        console.log('isError \'' + success + '\' and \'' + error + '\'');
        return error !== '' && (success === '' || typeof success === 'undefined');
    }

    private static isSuccess(success: string, error: string) {
        console.log('isSuccess \'' + success + '\' and \'' + error + '\'');
        return success !== '' && (error === '' || typeof error === 'undefined');
    }

    static html(success: string, error: string) {
        var messages;
        const language = navigator.language.split(/[-_]/)[0];
        if (language === 'fr') {
            messages = messages_fr;
        } else {
            messages = messages_en;
        }

        if (Response.isSuccess(success, error)) {
            // @ts-ignore
            var successMsg = messages[success.split(' | ')[0]];
            console.log('Detected a success');
            return (
                <div className='alert alert-success' role='alert'>
                    {successMsg}
                </div>
            );
        } else if (Response.isError(success, error)) {
            // @ts-ignore
            var errorMsg = messages[error.split(' | ')[0]];
            console.log('Detected an error');
            return (
                <div className='alert alert-danger' role='alert'>
                    {'Code : ' + error.split(' | ')[1]}
                    <br />
                    <br />
                    {errorMsg}
                </div>
            );
        } else {
            console.log('Detected nothing');
            return <div></div>;
        }
    }
}

export default Response;
