import React from 'react';
import ProductDTO from '../../model/ProductDTO';

function RowRetailerProduct(props: { product: ProductDTO }) {
    return (
        <tr>
            <td>{props.product.name}</td>
            <td>{props.product.quantity}</td>
            <td>{props.product.price}</td>
            <td>{props.product.companyName}</td>
        </tr>
    );
}

export default RowRetailerProduct;
