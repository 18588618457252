import React, {Suspense, useEffect, useState} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Response from '../model/Response';
import Translator from '../translations/Translator';
import QueryStringService from '../service/QueryStringService';

function PageCheckout() {
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        let msg_value = QueryStringService.getString('msg');
        if (msg_value != null) {
            setMsg(msg_value);
        }
        let email_value = QueryStringService.getString('email');
        if (email_value != null) {
            setEmail(email_value);
        }
        if (email == null) {
            setError('i18n.missing.email');
        }
    }, []);

    const checkout = () => {
        console.debug('checking out');
        var query = process.env.REACT_APP_API_URL + '/v1/checkout?email=' + email;
        console.debug(query);
        fetch(query, {
            method: 'POST'
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    let link = res['value'] + '?prefilled_email=' + email;
                    console.debug('Sending to ' + link);
                    window.location.replace(link);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Checkout - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <Translator value={msg} />
                    </div>

                    <div className="d-grid mb-3">
                        <button onClick={checkout} className="form-control btn btn-secondary btn-lg">
                            <Translator value="i18n.checkout" />
                        </button>
                    </div>
                    {Response.html(success, error)}
                </div>
                <div className="col-md-4"></div>

                <Footer />
            </div>
        </Suspense>
    );
}

export default PageCheckout;
