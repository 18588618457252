import React from 'react';
import CompanyDTO from '../../model/CompanyDTO';
import RowCompany from '../row/RowCompany';
import Translator from '../../translations/Translator';

function TableCompany(props: { data: Array<CompanyDTO>; type: string }) {
    // @ts-ignore
    return (
        <table className="table table-striped table-hover table-dark table-sm table-responsive-sm">
            <thead>
                <tr>
                    <th scope="col" className="col-2">
                        <Translator value="i18n.code" />
                    </th>
                    <th scope="col" className="col-4">
                        <Translator value="i18n.name" />
                    </th>
                    <th scope="col" className="col-4">
                        <Translator value="i18n.contact" />
                    </th>
                    <th scope="col" className="col-1"></th>
                    <th scope="col" className="col-1"></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowCompany company={item} type={props.type} />
                ))}
            </tbody>
        </table>
    );
}

export default TableCompany;
