import React, {Suspense} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Translator from '../translations/Translator'; // Html generated from markdown at https://markdowntohtml.com/

// Html generated from markdown at https://markdowntohtml.com/
function PageDocumentation() {
    document.title = 'Documentation - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div>
                <Header />

                <div className="row">
                    <div className="col-md-3">
                        <h4>Zizier documentation</h4>
                        <small>Last updated: November 2022</small>
                        <hr />
                        <ul>
                            <li>
                                <a href="#getting-started" className="no-decoration">
                                    Getting started
                                </a>
                                <ul>
                                    <li>
                                        <a href="#register" className="no-decoration">
                                            Register
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#log-in" className="no-decoration">
                                            Log in
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#products" className="no-decoration">
                                    Products
                                </a>
                                <ul>
                                    <li>
                                        <a href="#add-a-product" className="no-decoration">
                                            Add a product
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#edit-a-product" className="no-decoration">
                                            Edit a product
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#delete-a-product" className="no-decoration">
                                            Delete a product
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#sales" className="no-decoration">
                                    Sales
                                </a>
                                <ul>
                                    <li>
                                        <a href="#add-a-sale" className="no-decoration">
                                            Add a sale
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#edit-a-sale" className="no-decoration">
                                            Edit a sale
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#delete-a-sale" className="no-decoration">
                                            Delete a sale
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#print-a-sale" className="no-decoration">
                                            Print a sale
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#retailers" className="no-decoration">
                                    Retailers
                                </a>
                                <ul>
                                    <li>
                                        <a href="#view-a-retailer" className="no-decoration">
                                            View a retailer
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#delete-a-retailer" className="no-decoration">
                                            Delete a retailer
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#retailer-s-products" className="no-decoration">
                                    Retailer&#39;s products
                                </a>
                                <ul>
                                    <li>
                                        <a href="#view-list-of-retailer-s-products" className="no-decoration">
                                            View list of retailer&#39;s products
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#suppliers" className="no-decoration">
                                    Suppliers
                                </a>
                                <ul>
                                    <li>
                                        <a href="#add-a-supplier" className="no-decoration">
                                            Add a supplier
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#view-a-supplier" className="no-decoration">
                                            View a supplier
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#delete-a-supplier" className="no-decoration">
                                            Delete a supplier
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#received-invitations" className="no-decoration">
                                    Received invitations
                                </a>
                                <ul>
                                    <li>
                                        <a href="#view-who-invites-you" className="no-decoration">
                                            View who invites you
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#reply-to-an-invitation" className="no-decoration">
                                            Reply to an invitation
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#sent-invitations" className="no-decoration">
                                    Sent invitations
                                </a>
                                <ul>
                                    <li>
                                        <a href="#invite-a-supplier" className="no-decoration">
                                            Invite a supplier
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#delete-a-sent-invitation" className="no-decoration">
                                            Delete a sent invitation
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#rules-for-alerts-" className="no-decoration">
                                    Rules (For alerts)
                                </a>
                                <ul>
                                    <li>
                                        <a href="#add-a-rule" className="no-decoration">
                                            Add a rule
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#edit-a-rule" className="no-decoration">
                                            Edit a rule
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#delete-a-rule" className="no-decoration">
                                            Delete a rule
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#users" className="no-decoration">
                                    Users
                                </a>
                                <ul>
                                    <li>
                                        <a href="#add-a-user" className="no-decoration">
                                            Add a user
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#edit-a-user" className="no-decoration">
                                            Edit a user
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#delete-a-user" className="no-decoration">
                                            Delete a user
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#profile" className="no-decoration">
                                    Profile
                                </a>
                                <ul>
                                    <li>
                                        <a href="#edit-username" className="no-decoration">
                                            Edit username
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#edit-company-information" className="no-decoration">
                                            Edit company information
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#delete-account" className="no-decoration">
                                            Delete account
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#change-password" className="no-decoration">
                                            Change password
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <hr />
                        <br />
                    </div>
                    <div className="col-md-9">
                        {/*<h1 id='zizier-documentation'>Zizier documentation</h1>*/}
                        {/*<h5 id='last-updated-november-2022'>Last updated: November 2022</h5>*/}
                        {/*<br />*/}
                        <h2 id="getting-started">Getting started</h2>
                        <h4 id="register">Register</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Sign-up</strong> page in the ui or by accessing <a href="https://www.zizier.com/register">https://www.zizier.com/register</a>
                            </li>
                            <li>Enter your email address</li>
                            <li>
                                Click on <strong>Register</strong>
                            </li>
                            <li>A mail will be sent to your mailbox</li>
                            <li>Open your mailbox and click on the link in the mail</li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>username</li>
                                    <li>password</li>
                                    <li>confirm password</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Create user</strong>
                            </li>
                        </ul>
                        <h4 id="log-in">Log in</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Sign-in</strong> page in the ui or by accessing <a href="https://www.zizier.com/login">https://www.zizier.com/login</a>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>email address</li>
                                    <li>password</li>
                                    <li>
                                        Click on <strong>Log in</strong>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="products">Products</h2>
                        <p>Products represent the items you have in stock together with their prices and quantities</p>
                        <h4 id="add-a-product">Add a product</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Products</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/products">https://www.zizier.com/dashboard/products</a>
                            </li>
                            <li>
                                Click the button <strong>Add</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>name</li>
                                    <li>quantity</li>
                                    <li>price</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Save</strong>
                            </li>
                        </ul>
                        <h4 id="edit-a-product">Edit a product</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Products</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/products">https://www.zizier.com/dashboard/products</a>
                            </li>
                            <li>Find the product you want to edit</li>
                            <li>
                                Click the button <strong>Edit</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>name</li>
                                    <li>quantity</li>
                                    <li>price</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Save</strong>
                            </li>
                        </ul>
                        <h4 id="delete-a-product">Delete a product</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Products</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/products">https://www.zizier.com/dashboard/products</a>
                            </li>
                            <li>Find the product you want to delete</li>
                            <li>
                                Click the button <strong>Delete</strong>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="sales">Sales</h2>
                        <p>Sales represents the transactions made with your clients</p>
                        <h4 id="add-a-sale">Add a sale</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Sales</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/sales">https://www.zizier.com/dashboard/sales</a>
                            </li>
                            <li>
                                Click the button <strong>Add</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>customer name</li>
                                    <li>
                                        For every product sold, select the product, add the quantity purchased and click on <strong>Save</strong>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h4 id="edit-a-sale">Edit a sale</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Sales</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/sales">https://www.zizier.com/dashboard/sales</a>
                            </li>
                            <li>Find the sale you want to edit</li>
                            <li>
                                Click the button <strong>Edit</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>customer name</li>
                                    <li>
                                        For every product sold, select the product, add the quantity purchased and click on <strong>Save</strong>
                                    </li>
                                    <li>If you want to delete a sold product, click on the delete button beside the sold product</li>
                                    <li>
                                        If you want to add a sold product, select the product, add the quantity purchased and click on <strong>Save</strong>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h4 id="delete-a-sale">Delete a sale</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Sales</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/sales">https://www.zizier.com/dashboard/sales</a>
                            </li>
                            <li>Find the sale you want to delete</li>
                            <li>
                                Click the button <strong>Delete</strong>
                            </li>
                        </ul>
                        <h4 id="print-a-sale">Print a sale</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Sales</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/sales">https://www.zizier.com/dashboard/sales</a>
                            </li>
                            <li>Find the sale you want to print</li>
                            <li>
                                Click the button <strong>Print</strong>
                            </li>
                            <li>
                                The invoice will appear, once more, click on the <strong>Print</strong> button
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="retailers">Retailers</h2>
                        <p>Retailers represent the collaborators that are buying from you</p>
                        <p>It is currently not possible to add a retailer in zizier</p>
                        <p>You can see the products of your retailers and create alerts on them</p>
                        <h4 id="view-a-retailer">View a retailer</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Retailers</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/retailers">https://www.zizier.com/dashboard/retailers</a>
                            </li>
                            <li>Find the retailer you want to view</li>
                            <li>
                                Click the button <strong>View</strong>
                            </li>
                        </ul>
                        <h4 id="delete-a-retailer">Delete a retailer</h4>
                        <p>You will not be able to see the products of this retailer anymore or create alerts on its products</p>
                        <ul>
                            <li>
                                Navigate to the <strong>Retailers</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/retailers">https://www.zizier.com/dashboard/retailers</a>
                            </li>
                            <li>Find the retailer you want to delete</li>
                            <li>
                                Click the button <strong>Delete</strong>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="retailer-s-products">Retailer&#39;s products</h2>
                        <p>Retailer&#39;s products represents the products of the collaborators that are buying from you</p>
                        <p>It is currently not possible to add a retailer in zizier</p>
                        <h4 id="view-list-of-retailer-s-products">View list of retailer&#39;s products</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Retailer&#39;s products</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/retailers">https://www.zizier.com/dashboard/retailers</a>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="suppliers">Suppliers</h2>
                        <p>Suppliers represent the collaborators you are buying from</p>
                        <p>A supplier can view your products and create alerts on your products</p>
                        <h4 id="add-a-supplier">Add a supplier</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Suppliers</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/suppliers">https://www.zizier.com/dashboard/suppliers</a>
                            </li>
                            <li>
                                Click the button <strong>Add</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>email address</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Invite supplier</strong>
                            </li>
                        </ul>
                        <h4 id="view-a-supplier">View a supplier</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Suppliers</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/suppliers">https://www.zizier.com/dashboard/suppliers</a>
                            </li>
                            <li>Find the supplier you want to view</li>
                            <li>
                                Click the button <strong>View</strong>
                            </li>
                        </ul>
                        <h4 id="delete-a-supplier">Delete a supplier</h4>
                        <p>This supplier will not be able to see your products anymore or create alerts on your products</p>
                        <ul>
                            <li>
                                Navigate to the <strong>Suppliers</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/suppliers">https://www.zizier.com/dashboard/suppliers</a>
                            </li>
                            <li>Find the supplier you want to delete</li>
                            <li>
                                Click the button <strong>Delete</strong>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="received-invitations">Received invitations</h2>
                        <p>Received invitations represent the invitations sent to you by your retailers</p>
                        <h4 id="view-who-invites-you">View who invites you</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Received invitations</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/invitations/received">https://www.zizier.com/dashboard/invitations/received</a>
                            </li>
                            <li>Find the received invitation you want to view</li>
                            <li>
                                Click the button <strong>View</strong>
                            </li>
                        </ul>
                        <h4 id="reply-to-an-invitation">Reply to an invitation</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Received invitations</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/invitations/received">https://www.zizier.com/dashboard/invitations/received</a>
                            </li>
                            <li>Find the received invitation you want to reply to</li>
                            <li>
                                Click the button <strong>Reply</strong>
                            </li>
                            <li>
                                Either click <strong>Accept invitation or Reject invitation</strong>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="sent-invitations">Sent invitations</h2>
                        <p>Sent invitations represent the invitations you have sent to your suppliers</p>
                        <h4 id="invite-a-supplier">Invite a supplier</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Sent invitations</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/invitations/sent">https://www.zizier.com/dashboard/invitations/sent</a>
                            </li>
                            <li>
                                Click the button <strong>Add</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>email address</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Invite supplier</strong>
                            </li>
                        </ul>
                        <h4 id="delete-a-sent-invitation">Delete a sent invitation</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Sent invitations</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/invitations/sent">https://www.zizier.com/dashboard/invitations/sent</a>
                            </li>
                            <li>Find the invitation you want to delete</li>
                            <li>
                                Click the button <strong>Delete</strong>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="rules-for-alerts-">Rules (For alerts)</h2>
                        <p>Rules represent conditions that should be verified to trigger an alert</p>
                        <h4 id="add-a-rule">Add a rule</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Rules</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/rules">https://www.zizier.com/dashboard/rules</a>
                            </li>
                            <li>
                                Click the button <strong>Add</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>name</li>
                                    <li>quantity: The quantity that should be reached before the rule is triggered</li>
                                    <li>target: The company whose products should be monitored, can be a retailer</li>
                                    <li>product (optional): The product which is targeted by this rule</li>
                                    <li>
                                        emails to notify: The emails that will receive a notification when this rule is verified, for every email to notify, write the email and click on{' '}
                                        <strong>Save</strong>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Example: In order to email <strong>test@test.com</strong> everytime the product <strong>Macbook Pro</strong> belonging to the company <strong>Apple</strong>
                                gets below 10 units, we will provide the information below:
                                <ul>
                                    <li>name: Macbook Pro is less than 10 units</li>
                                    <li>quantity: 10</li>
                                    <li>target: Apple</li>
                                    <li>product: Macbook Pro</li>
                                    <li>emails to notify: test@test.com</li>
                                </ul>
                            </li>
                        </ul>
                        <h4 id="edit-a-rule">Edit a rule</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Rules</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/rules">https://www.zizier.com/dashboard/rules</a>
                            </li>
                            <li>Find the rule you want to edit</li>
                            <li>
                                Click the button <strong>Edit</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>name</li>
                                    <li>quantity: The quantity that should be reached before the rule is triggered</li>
                                    <li>target: The company whose products should be monitored, can be a retailer</li>
                                    <li>product (optional): The product which is targeted by this rule</li>
                                    <li>
                                        emails to notify: The emails that will receive a notification when this rule is verified. For every email to notify, write the email and click on{' '}
                                        <strong>Save</strong> or remove an email to notify by clicking on <strong>Delete</strong> beside the email
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h4 id="delete-a-rule">Delete a rule</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Rules</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/rules">https://www.zizier.com/dashboard/rules</a>
                            </li>
                            <li>Find the rule you want to delete</li>
                            <li>
                                Click the button <strong>Delete</strong>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="users">Users</h2>
                        <p>Users represent the persons that have access to your account</p>
                        <p>There are two types of users, ADMIN and SELLER</p>
                        <ul>
                            <li>ADMIN: Can do everything on the platform</li>
                            <li>SELLER: Can manage sales and can only view everything else</li>
                        </ul>
                        <h4 id="add-a-user">Add a user</h4>
                        <p>Users that are invited are by default SELLER users, this can be changed after registration</p>
                        <ul>
                            <li>
                                Navigate to the <strong>Users</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/users">https://www.zizier.com/dashboard/users</a>
                            </li>
                            <li>
                                Click the button <strong>Add</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>email address</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Invite user</strong>
                            </li>
                        </ul>
                        <h4 id="edit-a-user">Edit a user</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Users</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/users">https://www.zizier.com/dashboard/users</a>
                            </li>
                            <li>Find the user you want to edit</li>
                            <li>
                                Click the button <strong>Edit</strong>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>Role</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Save</strong>
                            </li>
                        </ul>
                        <h4 id="delete-a-user">Delete a user</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Users</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/users">https://www.zizier.com/dashboard/users</a>
                            </li>
                            <li>Find the user you want to delete</li>
                            <li>
                                Click the button <strong>Delete</strong>
                            </li>
                        </ul>
                        <br />
                        <hr />
                        <br />
                        <h2 id="profile">Profile</h2>
                        <h4 id="edit-username">Edit username</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Profile</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/profile">https://www.zizier.com/dashboard/profile</a>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>Full name</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Save user</strong>
                            </li>
                        </ul>
                        <h4 id="edit-company-information">Edit company information</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Profile</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/profile">https://www.zizier.com/dashboard/profile</a>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>name</li>
                                    <li>address</li>
                                    <li>description</li>
                                    <li>receipt phone number: Phone number displayed on receipts</li>
                                    <li>contact phone number: Phone number displayed to collaborators on zizier</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Save company</strong>
                            </li>
                        </ul>
                        <h4 id="delete-account">Delete account</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Profile</strong> page in the ui or by accessing <a href="https://www.zizier.com/dashboard/profile">https://www.zizier.com/dashboard/profile</a>
                            </li>
                            <li>
                                Click on <strong>Delete</strong>
                            </li>
                        </ul>
                        <h4 id="change-password">Change password</h4>
                        <ul>
                            <li>
                                Navigate to the <strong>Change password</strong> page in the ui or by accessing{' '}
                                <a href="https://www.zizier.com/dashboard/password">https://www.zizier.com/dashboard/password</a>
                            </li>
                            <li>
                                Enter the required information
                                <ul>
                                    <li>current password</li>
                                    <li>new password</li>
                                    <li>confirm new password</li>
                                </ul>
                            </li>
                            <li>
                                Click on <strong>Update password</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageDocumentation;
