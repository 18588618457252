import React from 'react';
import ButtonDelete from '../button/ButtonDelete';
import UserDTO from '../../model/UserDTO';
import ButtonNavGray from '../button/ButtonNavGray';

function RowUser(props: { user: UserDTO }) {
    var url = '/dashboard/users/form?id=' + props.user.id;
    return (
        <tr>
            <td>{props.user.name}</td>
            <td>{props.user.email}</td>
            <td>
                <ButtonNavGray value="i18n.edit" action={url} />
            </td>
            <td>
                <ButtonDelete id={props.user.id} type="user" />
            </td>
        </tr>
    );
}

export default RowUser;
