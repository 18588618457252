import React, {Suspense} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Translator from '../translations/Translator';

function Page404() {
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <br />
                    <br />
                    <br />
                    <h1 className="text-lg-center">
                        <Translator value="i18n.page.404" />
                    </h1>
                    <br />
                    <img src="404.png" width="100%" alt="error" />
                    <br />
                    <br />
                    <br />
                </div>
                <div className="col-md-4"></div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default Page404;
