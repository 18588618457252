import React from 'react';
import ProductDTO from '../../model/ProductDTO';
import RowRetailerProduct from '../row/RowRetailerProduct';
import Translator from '../../translations/Translator';

function TableRetailerProduct(props: { data: Array<ProductDTO> }) {
    // @ts-ignore
    return (
        <table className="table table-striped table-hover table-dark table-sm table-responsive-sm">
            <thead>
                <tr>
                    <th scope="col" className="col-5">
                        <Translator value="i18n.name" />
                    </th>
                    <th scope="col" className="col-1">
                        <Translator value="i18n.quantity" />
                    </th>
                    <th scope="col" className="col-1">
                        <Translator value="i18n.price" />
                    </th>
                    <th scope="col" className="col-5">
                        <Translator value="i18n.company" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowRetailerProduct product={item} />
                ))}
            </tbody>
        </table>
    );
}

export default TableRetailerProduct;
