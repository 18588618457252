import React from 'react';
import Translator from '../../translations/Translator';

function ButtonNavGray(props: { action: string; value: string }) {
    return (
        <a href={props.action} className="no-decoration">
            <button className="btn btn-secondary btn-sm">
                <Translator value={props.value} />
            </button>
        </a>
    );
}

export default ButtonNavGray;
