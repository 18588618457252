import React from 'react';
import Translator from '../../translations/Translator';

function ButtonEdit(props: { action: string }) {
    return (
        <a href={props.action} className="no-decoration">
            <button className="btn btn-secondary btn-sm">
                <Translator value="i18n.edit" />
            </button>
        </a>
    );
}

export default ButtonEdit;
