import React, {Suspense, useState} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Response from '../model/Response';
import Translator from '../translations/Translator';
import Required from '../shared/Required';

function PageRecoverReply() {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handlePassword = (event: { target: { value: any } }) => {
        setPassword(event.target.value);
    };

    const handlePasswordConfirm = (event: { target: { value: any } }) => {
        setPasswordConfirm(event.target.value);
    };

    const recover = () => {
        console.debug('recovering');

        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        if (password !== passwordConfirm) {
            setSuccess('');
            setError(Response.i18n['error-passwords-different']);
            return;
        }

        var query = process.env.REACT_APP_API_URL + '/v1/user/password/recover/2?token=' + token + '&new_password=' + password;
        console.debug(query);
        fetch(query, {
            method: 'PUT'
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-password-changed']);
                    setError('');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Recover password reply - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <Translator value="i18n.new.password" />
                        <Required />
                        <input onChange={handlePassword} type="password" className="form-control form-control-lg" />
                    </div>
                    <div className="mb-4">
                        <Translator value="i18n.confirm.new.password" />
                        <Required />
                        <input onChange={handlePasswordConfirm} type="password" className="form-control form-control-lg" />
                    </div>

                    <div className="d-grid mb-3">
                        <button onClick={recover} className="form-control btn btn-secondary btn-lg">
                            <Translator value="i18n.change.password" />
                        </button>
                    </div>

                    {Response.html(success, error)}
                </div>
                <div className="col-md-4"></div>

                <Footer />
            </div>
        </Suspense>
    );
}

export default PageRecoverReply;
