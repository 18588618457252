import React, {Suspense} from 'react';
import DashboardLeft from '../../shared/DashboardLeft';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import FormUser from '../form/FormUser';
import Translator from '../../translations/Translator';
import Line from '../../shared/Line';

function PageUsersForm() {
    document.title = 'User - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <DashboardLeft />
                <div className="col-md-9">
                    <h5>
                        <Translator value="i18n.user.form" />
                    </h5>
                    <Line />
                    <FormUser />
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageUsersForm;
