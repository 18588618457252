import React, {useState} from 'react';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';
import Translator from '../../translations/Translator';

function ButtonDelete(props: { id: string; type: string }) {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleClick = () => {
        var query = process.env.REACT_APP_API_URL + '/v1/' + props.type + '?id=' + props.id;
        console.debug(query);
        fetch(query, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(Response.i18n['error-cannot-delete']);
                } else {
                    console.debug('deleted id ' + props.id + ' for type ' + props.type);
                    if (props.type == 'user/account') {
                        SessionService.logout();
                        window.location.replace('/');
                    } else {
                        window.location.reload();
                    }
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };

    return (
        <div>
            <button className="btn btn-danger btn-sm" onClick={handleClick}>
                <Translator value="i18n.delete" />
            </button>
            {Response.html(success, error)}
        </div>
    );
}

export default ButtonDelete;
