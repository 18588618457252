import React from 'react';
import Translator from '../translations/Translator';

function Footer() {
    return (
        <footer className='footer mt-auto py-3'>
            <br />
            <br />

            <div className='container pb-1 pb-lg-5 text-lg-center'>
                <a href='https://play.google.com/store/apps/details?id=com.zizier.zizier'
                   target='_blank'><img src='/img/google_play_64.png' alt='google play' /></a>
                <br /><br />
                <a href='https://www.facebook.com/ziziersoftware' target='_blank'>
                <button className='btn btn-secondary' type='button'>

                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor'
                         className='bi bi-facebook' viewBox='0 0 16 16'>
                      <path
                          d='M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z' />
                    </svg>

                </button>
                </a>
                &nbsp;&nbsp;
                <a href='https://www.pinterest.com/ziziersoftware' target='_blank'>
                <button className='btn btn-secondary' type='button'>
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor'
                     className='bi bi-pinterest' viewBox='0 0 16 16'>
                  <path
                      d='M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z' />
                </svg>
                    </button>
                </a>
                &nbsp;&nbsp;
                <a href='https://twitter.com/ziziersoftware' target='_blank'>
                <button className='btn btn-secondary' type='button'>
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor'
                     className='bi bi-twitter' viewBox='0 0 16 16'>
                  <path
                      d='M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z' />
                </svg>
                    </button>
                </a>
                <br />
                <br />
                <img src='/img/mastercard_16.png' alt='mastercard' />
                &nbsp;&nbsp;
                <img src='/img/visa_16.png' alt='visa' />
                &nbsp;&nbsp;
                <img src='/img/american_express_32.png' alt='american express' />
                &nbsp;&nbsp;
                <img src='/img/union_pay_16.png' alt='union pay' />
                <br />
                <br />
                <p className='small'><a className='no-decoration' href='https://api.zizier.com/swagger-ui/index.html'
                                        target='_blank'>API</a>&nbsp;-&nbsp;<a className='no-decoration'
                                                                               href='https://stats.uptimerobot.com/R70XMiGDPg'
                                                                               target='_blank'><Translator
                    value='i18n.status.page' /></a></p>
                <p className='small'><a className='no-decoration' href='/privacy'>Privacy policy</a></p>
                <p className='small'>© zizier.com - All rights reserved</p>
                {/*<p className='small'>Powered by : Zizier PMV</p>*/}
            </div>
        </footer>
    );
}

export default Footer;
