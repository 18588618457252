import React, {Suspense, useState} from 'react';
import DashboardLeft from '../../shared/DashboardLeft';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import Response from '../../model/Response';
import SessionService from '../../service/SessionService';
import Translator from '../../translations/Translator';
import Line from '../../shared/Line';

function PagePassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordconfirm, setPasswordconfirm] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handlePassword = (event: { target: { value: any } }) => {
        setPassword(event.target.value);
    };

    const handleOldPassword = (event: { target: { value: any } }) => {
        setOldPassword(event.target.value);
    };

    const handlePasswordconfirm = (event: { target: { value: any } }) => {
        setPasswordconfirm(event.target.value);
    };

    const change = () => {
        console.debug('change password');

        if (password !== passwordconfirm) {
            setSuccess('');
            setError(Response.i18n['error-passwords-different']);
            return;
        }

        var query = process.env.REACT_APP_API_URL + '/v1/user/password/update?id=' + SessionService.read(SessionService.userIdKey) + '&old_password=' + oldPassword + '&new_password=' + password;
        console.debug(query);
        fetch(query, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-password-changed']);
                    setError('');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Change password - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <DashboardLeft />
                <div className="col-md-9">
                    <div className="container-md">
                        <h5>
                            <Translator value="i18n.password.information" />
                        </h5>
                        <Line />
                        <div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.current.password" /> :
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="password" onChange={handleOldPassword} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.new.password" /> :
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="password" onChange={handlePassword} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.confirm.new.password" /> :
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="password" onChange={handlePasswordconfirm} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <button onClick={change} className="form-control btn btn-secondary btn-lg">
                                    <Translator value="i18n.update.password" />
                                </button>
                            </div>
                            {Response.html(success, error)}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PagePassword;
