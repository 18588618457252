import React from 'react';
import PageWelcome from './page/PageWelcome';
import PageProfile from './page/dashboard/PageProfile';
import PageLogin from './page/PageLogin';
import PageRecover from './page/PageRecover';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Page404 from './page/Page404';
import PageRegister from './page/PageRegister';
import PageProducts from './page/dashboard/PageProducts';
import PagePassword from './page/dashboard/PagePassword';
import PageRetailerProducts from './page/dashboard/PageRetailersProducts';
import PageRecoverReply from './page/PageRecoverReply';
import PageRegisterReply from './page/PageRegisterReply';
import PageInviteSupplier from './page/dashboard/PageInviteSupplier';
import PageInviteUser from './page/dashboard/PageInviteUser';
import PageInviteUserReply from './page/PageInviteUserReply';
import PageInviteSupplierReply from './page/PageInviteSupplierReply';
import PageProductsForm from './page/dashboard/PageProductsForm';
import PageSales from './page/dashboard/PageSales';
import PageSalesForm from './page/dashboard/PageSalesForm';
import PageSalesPrint from './page/dashboard/PageSalesPrint';
import PagePrint from './page/PagePrint';
import PageSuppliers from './page/dashboard/PageSuppliers';
import PageRetailers from './page/dashboard/PageRetailers';
import PageCompany from './page/dashboard/PageCompany';
import PageInvitationsSent from './page/dashboard/PageInvitationsSent';
import PageInvitationsReceived from './page/dashboard/PageInvitationsReceived';
import PageRules from './page/dashboard/PageRules';
import PageRulesForm from './page/dashboard/PageRulesForm';
import PageUsers from './page/dashboard/PageUsers';
import PageUsersForm from './page/dashboard/PageUsersForm';
import PageCheckout from './page/PageCheckout';
import PageWriteToUs from './page/PageWriteToUs';
import PagePrivacyPolicy from './page/PagePrivacyPolicy';
import PageDocumentation from './page/PageDocumentation';

export interface IApplicationProps {
}

const App: React.FunctionComponent<IApplicationProps> = (props) => {
    return (
        <div className='container-md'>
            <BrowserRouter>
                <Routes>
                    <Route path='/'>
                        <Route index element={<PageWelcome />} />
                        <Route path='login' element={<PageLogin />} />
                        <Route path='recover' element={<PageRecover />} />
                        <Route path='recover/reply' element={<PageRecoverReply />} />
                        <Route path='register' element={<PageRegister />} />
                        <Route path='register/reply' element={<PageRegisterReply />} />
                        <Route path='invite/supplier/reply' element={<PageInviteSupplierReply />} />
                        <Route path='invite/user/reply' element={<PageInviteUserReply />} />
                        <Route path='dashboard/profile' element={<PageProfile />} />
                        <Route path='dashboard/products' element={<PageProducts />} />
                        <Route path='dashboard/products/form' element={<PageProductsForm />} />
                        <Route path='dashboard/sales' element={<PageSales />} />
                        <Route path='dashboard/rules' element={<PageRules />} />
                        <Route path='dashboard/rules/form' element={<PageRulesForm />} />
                        <Route path='dashboard/invitations/sent' element={<PageInvitationsSent />} />
                        <Route path='dashboard/invitations/received' element={<PageInvitationsReceived />} />
                        <Route path='dashboard/company' element={<PageCompany />} />
                        <Route path='dashboard/sales/form' element={<PageSalesForm />} />
                        <Route path='dashboard/sales/print' element={<PageSalesPrint />} />
                        <Route path='dashboard/password' element={<PagePassword />} />
                        <Route path='dashboard/retailers/products' element={<PageRetailerProducts />} />
                        <Route path='dashboard/invite/supplier' element={<PageInviteSupplier />} />
                        <Route path='dashboard/invite/user' element={<PageInviteUser />} />
                        <Route path='dashboard/suppliers' element={<PageSuppliers />} />
                        <Route path='dashboard/retailers' element={<PageRetailers />} />
                        <Route path='dashboard/users' element={<PageUsers />} />
                        <Route path='dashboard/users/form' element={<PageUsersForm />} />
                        <Route path='*' element={<Page404 />} />
                        <Route path='print' element={<PagePrint />} />
                        <Route path='checkout' element={<PageCheckout />} />
                        <Route path='write' element={<PageWriteToUs />} />
                        <Route path='privacy' element={<PagePrivacyPolicy />} />
                        <Route path='documentation' element={<PageDocumentation />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
