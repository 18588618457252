import React, {Suspense, useState} from 'react';
import DashboardLeft from '../../shared/DashboardLeft';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import Response from '../../model/Response';
import Translator from '../../translations/Translator';
import SessionService from '../../service/SessionService';
import Line from '../../shared/Line';

function PageInviteSupplier() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleEmail = (event: { target: { value: any } }) => {
        setEmail(event.target.value);
    };

    const invite = () => {
        console.debug('registering with ' + email);
        var locale = navigator.language;
        var language = locale.split('-')[0];
        var country = locale.split('-')[1];
        let companyId = SessionService.read(SessionService.companyIdKey);
        let userEmail = SessionService.read(SessionService.emailKey);
        var query = process.env.REACT_APP_API_URL + '/v1/invitation/send?email=' + email + '&sender_email=' + userEmail + '&company_id=' + companyId + '&language=' + language + '&country=' + country;
        console.debug(query);
        fetch(query, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-email-sent']);
                    setError('');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Invite supplier - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <DashboardLeft />
                <div className="col-md-9">
                    <div className="container-md">
                        <h5>
                            <Translator value="i18n.invite supplier" />
                        </h5>
                        <Line />
                        <div className="container">
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.email.address" /> :
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" onChange={handleEmail} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <button onClick={invite} className="form-control btn btn-secondary btn-lg">
                                    <Translator value="i18n.invite.supplier" />
                                </button>
                            </div>
                            {Response.html(success, error)}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageInviteSupplier;
