import React from 'react';
import PageDTO from '../../model/PageDTO';
import PageItem from './PageItem';
import PaginationService from '../../service/PaginationService';

function Pagination(props: { data: PageDTO; link: string }) {
    return (
        <div className='flex-row'>
                {PaginationService.getNumbers(props.data).map(item => {
                    // @ts-ignore
                    return <PageItem number={item} link={props.link} />;
                })}
        </div>
    );
}

export default Pagination;
