import React from 'react';

function PageItem(props: { number: number; link: string }) {

    let pagelink = props.link + props.number;

    const queryParams = new URLSearchParams(window.location.search);
    let page: string | null = queryParams.get('page');
    if (page == null) {
        page = '1';
    }

    if (parseInt(page) == props.number) {
        return (
            <a>
                <button className='btn btn-dark'>
                {props.number}
                </button>
            </a>
        );
    } else {
        return (
            <a href={pagelink}>
                <button className='btn btn-secondary'>
                {props.number}
                </button>
            </a>
        );
    }
}

export default PageItem;
