import React from 'react';
import Translator from '../translations/Translator';
import SessionService from '../service/SessionService';

function HeaderLogin() {
    const logout = () => {
        console.debug('logging out');
        SessionService.logout();
        window.location.replace('/');
    };

    if (SessionService.isLoggedIn()) {
        return (
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link" href="/documentation">
                            <Translator value="i18n.read.the.documentation.2" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/dashboard/products">
                            <Translator value="i18n.dashboard" />
                        </a>
                    </li>
                </ul>
                <h5>
                    <Translator value="i18n.hi" /> {SessionService.read(SessionService.nameKey)}
                </h5>
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-danger" onClick={logout}>
                    <Translator value="i18n.logout" />
                </button>
                &nbsp;&nbsp;&nbsp;
            </div>
        );
    } else {
        return (
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link" href="/documentation">
                            <Translator value="i18n.read.the.documentation.2" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/write">
                            <Translator value="i18n.write.to.us" />
                        </a>
                    </li>
                </ul>
                <form className="d-flex" action="/login">
                    <button className="btn btn-secondary" type="submit">
                        <Translator value="i18n.sign.in" />
                    </button>
                </form>
                &nbsp;&nbsp;&nbsp;
                <form className="d-flex" action="/register">
                    <button className="btn btn-outline-secondary" type="submit">
                        <Translator value="i18n.sign.up" />
                    </button>
                </form>
                &nbsp;&nbsp;&nbsp;
            </div>
        );
    }
}

export default HeaderLogin;
