import React, {Suspense, useState} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Response from '../model/Response';
import Translator from '../translations/Translator';
import Required from '../shared/Required';
import SessionService from '../service/SessionService';

function PageRegisterReply() {
    const [username, setUsername] = useState('');
    //const [companyname, setCompanyname] = useState('');
    const [password, setPassword] = useState('');
    const [passwordconfirm, setPasswordconfirm] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleUsername = (event: { target: { value: any } }) => {
        setUsername(event.target.value);
    };

    /*const handleCompanyname = (event: { target: { value: any } }) => {
        setCompanyname(event.target.value);
    };*/

    const handlePassword = (event: { target: { value: any } }) => {
        setPassword(event.target.value);
    };

    const handlePasswordconfirm = (event: { target: { value: any } }) => {
        setPasswordconfirm(event.target.value);
    };

    const reply = () => {
        console.debug('registering reply');

        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        if (password !== passwordconfirm) {
            setSuccess('');
            setError(Response.i18n['error-passwords-different']);
            return;
        }

        var query = process.env.REACT_APP_API_URL + '/v1/user/register/self/receive?token=' + token + '&username=' + username + '&company_name=' + username + '&password=' + password;
        console.debug(query);
        fetch(query, {
            method: 'POST'
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-account-created']);
                    setError('');
                    SessionService.login(res.jwt);
                    window.location.replace('/dashboard/products');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Register reply - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <Translator value="i18n.username" />
                        <Required />
                        <input onChange={handleUsername} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-4">
                        <Translator value="i18n.password" />
                        <Required />
                        <input onChange={handlePassword} type="password" className="form-control form-control-lg" />
                    </div>
                    <div className="mb-4">
                        <Translator value="i18n.confirm.password" />
                        <Required />
                        <input onChange={handlePasswordconfirm} type="password" className="form-control form-control-lg" />
                    </div>

                    <div className="d-grid mb-3">
                        <button onClick={reply} className="form-control btn btn-secondary btn-lg">
                            <Translator value="i18n.create.user" />
                        </button>
                    </div>
                    {Response.html(success, error)}
                </div>
                <div className="col-md-4"></div>

                <Footer />
            </div>
        </Suspense>
    );
}

export default PageRegisterReply;
