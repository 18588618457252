import React from 'react';
import PageDTO from '../model/PageDTO';

class PaginationService {
    public static getNumbers(data: PageDTO) {
        let numbers: number[] = [];
        if (data.empty) {
            return numbers;
        }
        for (let i = 1; i < data.totalPages + 1; i++) {
            numbers.push(i);
        }
        return numbers;
    }

    public static getPage() {
        const queryParams = new URLSearchParams(window.location.search);
        let page: string | null = queryParams.get('page');
        if (page == null) {
            page = '0';
        } else {
            let value = parseInt(page);
            value = value - 1;
            page = value + '';
        }
        return page;
    }

    public static logout() {
        localStorage.clear();
    }
}

export default PaginationService;
