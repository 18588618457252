import React from 'react';
import Translator from '../../translations/Translator';

function ButtonFormBlue(props: { value: string }) {
    return (
        <div className="d-grid mb-3">
            <button type="submit" className="btn btn-secondary btn-sm">
                <Translator value={props.value}></Translator>
            </button>
        </div>
    );
}

export default ButtonFormBlue;
