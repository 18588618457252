import React from 'react';
import HeaderLogin from './HeaderLogin';

function Header() {
    return (
        <div>
            <nav className='navbar navbar-expand-lg navbar-light'>
                <a className='navbar-brand' href='/'>
                    &nbsp;&nbsp;&nbsp;
                    <img src='/icons/48.png' alt="48.png"/>
                </a>
                <a className='navbar-brand' href='/'>
                    <h1 className='fs-1'>{process.env.REACT_APP_NAME}</h1>
                </a>
                <button
                    className='navbar-toggler'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    <span className='navbar-toggler-icon'></span>
                </button>
                <HeaderLogin />
            </nav>
            <br />
        </div>
    );
}

export default Header;
