import React from 'react';
import SaleDTO from '../../model/SaleDTO';
import RowSale from '../row/RowSale';

function TableSales(props: { data: Array<SaleDTO> }) {
    // @ts-ignore
    return (
        <table className="table table-striped table-hover table-dark table-sm table-responsive-sm">
            <thead>
                <tr>
                    <th scope="col" className="col-2">
                        Date
                    </th>
                    <th scope="col" className="col-3">
                        Seller
                    </th>
                    <th scope="col" className="col-4">
                        Customer
                    </th>
                    <th scope="col" className="col-1"></th>
                    <th scope="col" className="col-1"></th>
                    <th scope="col" className="col-1"></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowSale sale={item} />
                ))}
            </tbody>
        </table>
    );
}

export default TableSales;
