import React, {lazy, Suspense} from 'react';

const Header = lazy(() => import('../shared/Header'));
const Footer = lazy(() => import('../shared/Footer'));
const Translator = lazy(() => import('../translations/Translator'));

function PageWelcome() {
    document.title = 'Inventory and sales management software - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="row">
                    <div className="col">
                        <div className=" rounded-3">
                            <div className="container-fluid">
                                <h2 className="display-6 fw-bold">
                                    <Translator value="i18n.welcome.1" />
                                </h2>
                                <p className="col-md-10 fs-5">
                                    <Translator value="i18n.welcome.2" />
                                </p>
                                <p className="col-md-10 fs-5">
                                    <Translator value="i18n.welcome.2.1" />
                                </p>
                                <p className="col-md-10 fs-5">
                                    <Translator value="i18n.welcome.2.2" />
                                </p>
                                <p className="col-md-10 fs-5">
                                    <Translator value="i18n.read.the.documentation" />
                                    <a href="/documentation">
                                        <Translator value="i18n.read.the.documentation.2" />
                                    </a>
                                </p>
                                <a href="/register">
                                    <button className="btn btn-secondary btn-lg">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-fill-add" viewBox="0 0 16 16">
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                            <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                                        </svg>
                                        &nbsp;&nbsp;
                                        <Translator value="i18n.sign.up.now" />
                                    </button>
                                    <br />
                                    <br />
                                </a>
                            </div>
                        </div>

                        <div className="row align-items-md-stretch">
                            <div className="col-md-6">
                                <div className="h-100 p-4 bg-secondary rounded-3 text-white">
                                    <h2>
                                        <Translator value="i18n.welcome.3" />
                                    </h2>
                                    <p>
                                        <Translator value="i18n.welcome.4" />
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="h-100 p-4 bg-secondary rounded-3 text-white">
                                    <h2>
                                        <Translator value="i18n.welcome.5" />
                                    </h2>
                                    <p>
                                        <Translator value="i18n.welcome.6" />
                                    </p>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="row align-items-md-stretch">
                            <div className="col-md-6">
                                <div className="h-100 p-4 theme-bg-light rounded-3">
                                    <h2>
                                        <Translator value="i18n.welcome.7" />
                                    </h2>
                                    <p>
                                        <Translator value="i18n.welcome.8" />
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="h-100 p-4 theme-bg-light rounded-3">
                                    <h2>
                                        <Translator value="i18n.welcome.9" />
                                    </h2>
                                    <p>
                                        <Translator value="i18n.welcome.10" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="row text-align-center">*/}
                {/*    <br />*/}
                {/*    <br />*/}

                {/*<div className="text-lg-center">*/}
                {/*    <br />*/}
                {/*    <br />*/}
                {/*    <h1 className="text-lg-center">*/}
                {/*        <Translator value="i18n.welcome.11" />*/}
                {/*    </h1>*/}
                {/*    <Translator value="i18n.welcome.12" />*/}
                {/*    <br />*/}
                {/*    <br />*/}
                {/*</div>*/}

                {/*<div className="col">*/}
                {/*    <div className="card mb-4 rounded-3 theme-bg-light">*/}
                {/*        <div className="card-header py-3">*/}
                {/*            <h4 className="my-0 fw-normal">*/}
                {/*                <Translator value="i18n.welcome.13" />*/}
                {/*            </h4>*/}
                {/*        </div>*/}
                {/*        <div className="card-body">*/}
                {/*            <h1 className="card-title pricing-card-title">*/}
                {/*                $0*/}
                {/*                <small className="text-muted fw-light">*/}
                {/*                    <Translator value="i18n.welcome.14" />*/}
                {/*                </small>*/}
                {/*            </h1>*/}
                {/*            <ul className="list-unstyled mt-3 mb-4">*/}
                {/*                /!*<li><Translator value='i18n.welcome.15' /></li>*!/*/}
                {/*                /!*<li><Translator value='i18n.welcome.16' /></li>*!/*/}
                {/*                <li>*/}
                {/*                    <Translator value="i18n.welcome.17" />*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col">*/}
                {/*    <div className="card mb-4 rounded-3 theme-bg-light">*/}
                {/*        <div className="card-header py-3">*/}
                {/*            <h4 className="my-0 fw-normal">*/}
                {/*                <Translator value="i18n.welcome.18" />*/}
                {/*            </h4>*/}
                {/*        </div>*/}
                {/*        <div className="card-body">*/}
                {/*            <h1 className="card-title pricing-card-title">*/}
                {/*                $4.99*/}
                {/*                <small className="text-muted fw-light">*/}
                {/*                    <Translator value="i18n.welcome.14" />*/}
                {/*                </small>*/}
                {/*            </h1>*/}
                {/*            <ul className="list-unstyled mt-3 mb-4">*/}
                {/*                /!*<li><Translator value='i18n.welcome.15' /></li>*!/*/}
                {/*                /!*<li><Translator value='i18n.welcome.19' /></li>*!/*/}
                {/*                <li>*/}
                {/*                    <Translator value="i18n.welcome.20" />*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*</div>*/}
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageWelcome;
