import React from 'react';
import PrintItemDTO from '../../model/PrintItemDTO';

function RowSaleItemPrint(props: { printItem: PrintItemDTO }) {
    return (
        <tr>
            <td>{props.printItem.name}</td>
            <td>{props.printItem.price}</td>
            <td>{props.printItem.quantity}</td>
            <td>{props.printItem.total}</td>
        </tr>
    );
}

export default RowSaleItemPrint;
