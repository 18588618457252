import React, {Suspense, useEffect, useState} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Response from '../model/Response';
import QueryStringService from '../service/QueryStringService';
import Translator from '../translations/Translator';
import Required from '../shared/Required';

function PageInviteSupplierReply() {
    const [username, setUsername] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [password, setPassword] = useState('');
    const [passwordconfirm, setPasswordconfirm] = useState('');
    const [existing, setExisting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [sender, setSender] = useState('');

    const handleUsername = (event: { target: { value: any } }) => {
        setUsername(event.target.value);
    };

    const handleCompanyname = (event: { target: { value: any } }) => {
        setCompanyname(event.target.value);
    };

    const handlePassword = (event: { target: { value: any } }) => {
        setPassword(event.target.value);
    };

    const handlePasswordconfirm = (event: { target: { value: any } }) => {
        setPasswordconfirm(event.target.value);
    };

    useEffect(() => {
        let token = QueryStringService.getString('token');

        if (token == null) {
            return;
        }

        var company = QueryStringService.getString('company');
        if (company != null) {
            setSender(company);
        }

        console.debug('getting user for token ' + token);

        var query;
        query = process.env.REACT_APP_API_URL + '/v1/invitation/receive/token/user?token=' + token;
        console.debug(query);
        fetch(query, {
            method: 'GET'
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    if (res.error !== 'i18n.not.found.user') {
                        setSuccess('');
                        setError(res.error + Response.support + res.id);
                    }
                } else {
                    setExisting(true);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    }, []);

    const accept = () => {
        console.debug('accepting reply');

        if (password !== passwordconfirm) {
            setSuccess('');
            setError(Response.i18n['error-passwords-different']);
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        var query = '';

        if (existing) {
            query = process.env.REACT_APP_API_URL + '/v1/invitation/receive/old/accept?token=' + token;
        } else {
            query = process.env.REACT_APP_API_URL + '/v1/invitation/receive/new/accept?token=' + token + '&username=' + username + '&company_name=' + companyname + '&password=' + password;
        }
        console.debug(query);
        fetch(query, {
            method: 'PUT'
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-invitation-accepted']);
                    setError('');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };

    const reject = () => {
        console.debug('rejecting reply');

        if (password !== passwordconfirm) {
            setSuccess('');
            setError(Response.i18n['error-passwords-different']);
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        var query = '';

        if (existing) {
            query = process.env.REACT_APP_API_URL + '/v1/invitation/receive/old/reject?token=' + token;
        } else {
            query = process.env.REACT_APP_API_URL + '/v1/invitation/receive/new/reject?token=' + token + '&username=' + username + '&company_name=' + companyname + '&password=' + password;
        }
        console.debug(query);
        fetch(query, {
            method: 'PUT'
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-invitation-rejected']);
                    setError('');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Invite supplier reply - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="container-md">
                        <div className="mb-3 row">
                            <label className="col-sm-4 form-label">
                                <Translator value="i18n.from" />
                            </label>
                            <div className="col-sm-8">
                                <input className="form-control" disabled value={sender} />
                            </div>
                        </div>
                        {!existing && (
                            <div className="mb-3 row">
                                <label className="col-sm-4 form-label">
                                    <Translator value="i18n.company" />
                                    <Required />
                                </label>
                                <div className="col-sm-8">
                                    <input className="form-control" onChange={handleCompanyname} />
                                </div>
                            </div>
                        )}
                        {!existing && (
                            <div className="mb-3 row">
                                <label className="col-sm-4 form-label">
                                    <Translator value="i18n.username" />
                                    <Required />
                                </label>
                                <div className="col-sm-8">
                                    <input className="form-control" onChange={handleUsername} />
                                </div>
                            </div>
                        )}
                        {!existing && (
                            <div className="mb-3 row">
                                <label className="col-sm-4 form-label">
                                    <Translator value="i18n.password" />
                                    <Required />
                                </label>
                                <div className="col-sm-8">
                                    <input className="form-control" type="password" onChange={handlePassword} />
                                </div>
                            </div>
                        )}
                        {!existing && (
                            <div className="mb-3 row">
                                <label className="col-sm-4 form-label">
                                    <Translator value="i18n.confirm.password" />
                                    <Required />
                                </label>
                                <div className="col-sm-8">
                                    <input className="form-control" type="password" onChange={handlePasswordconfirm} />
                                </div>
                            </div>
                        )}

                        <div className="d-grid mb-3">
                            <button type="submit" className="form-control btn btn-secondary btn-lg" onClick={accept}>
                                <Translator value="i18n.accept.invitation" />
                            </button>
                        </div>
                        <div className="d-grid mb-3">
                            <button type="submit" className="btn btn-danger btn-lg" onClick={reject}>
                                <Translator value="i18n.reject.invitation" />
                            </button>
                        </div>
                        {Response.html(success, error)}
                    </div>
                </div>
                <div className="col-md-4"></div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageInviteSupplierReply;
