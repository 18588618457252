import React, {Suspense, useEffect, useState} from 'react';
import DashboardLeft from '../../shared/DashboardLeft';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import ButtonNavBlue from '../button/ButtonNavBlue';
import Pagination from '../pagination/Pagination';
import Response from '../../model/Response';
import TableSales from '../table/TableSales';
import PageDTO from '../../model/PageDTO';
import SessionService from '../../service/SessionService';
import PaginationService from '../../service/PaginationService';
import Translator from '../../translations/Translator';
import Line from '../../shared/Line';

function PageSales() {
    const [data, setData] = useState<PageDTO>(new PageDTO());
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        var query = process.env.REACT_APP_API_URL + '/v1/sale/paged?company_id=' + SessionService.read(SessionService.companyIdKey) + '&page_no=' + PaginationService.getPage();
        console.debug(query);
        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    console.debug('data before :' + data);
                    setData(res);
                    console.debug('data after :' + data);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    }, []);
    document.title = 'Sales list - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <DashboardLeft />
                <div className="col-md-9">
                    <h5>
                        <Translator value="i18n.list.of.sales" />
                    </h5>
                    <Line />
                    <ButtonNavBlue value="i18n.add" action="/dashboard/sales/form" />
                    <Pagination data={data} link="/dashboard/sales?page=" />
                    <div className="table-responsive">
                        <TableSales data={data.content} />
                        {Response.html(success, error)}
                    </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageSales;
