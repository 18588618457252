import React from 'react';
import RowProduct from '../row/RowProduct';
import ProductDTO from '../../model/ProductDTO';
import Translator from '../../translations/Translator';

function TableProducts(props: { data: Array<ProductDTO> }) {
    // @ts-ignore
    return (
        <table className="table table-striped table-hover table-dark table-sm table-responsive-sm">
            <thead>
                <tr>
                    <th scope="col" className="col-6">
                        <Translator value="i18n.name" />
                    </th>
                    <th scope="col" className="col-2">
                        <Translator value="i18n.quantity" />
                    </th>
                    <th scope="col" className="col-2">
                        <Translator value="i18n.price" />
                    </th>
                    <th scope="col" className="col-1"></th>
                    <th scope="col" className="col-1"></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowProduct product={item} />
                ))}
            </tbody>
        </table>
    );
}

export default TableProducts;
