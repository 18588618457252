import React, {useEffect, useState} from 'react';
import Response from '../../model/Response';
import SessionService from '../../service/SessionService';
import Translator from '../../translations/Translator';
import Required from '../../shared/Required';

function FormProduct() {
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        if (id != null) {
            console.debug('editing a product');
            var query;
            query = process.env.REACT_APP_API_URL + '/v1/product?id=' + id;
            console.debug(query);
            fetch(query, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
                }
            })
                .then((res) => res.json())
                .then((res) => {
                    console.debug(res);
                    if (res.hasOwnProperty('error')) {
                        setSuccess('');
                        setError(res.error + Response.support + res.id);
                    } else {
                        setName(res.name);
                        setQuantity(res.quantity);
                        setPrice(res.price);
                    }
                })
                .catch((error) => {
                    console.debug(error);
                    setError(Response.i18n['error-unknown']);
                    setSuccess('');
                });
        }
    }, []);

    const handleName = (event: { target: { value: any } }) => {
        setName(event.target.value);
    };

    const handleQuantity = (event: { target: { value: any } }) => {
        setQuantity(event.target.value);
    };

    const handlePrice = (event: { target: { value: any } }) => {
        setPrice(event.target.value);
    };

    const submit = () => {
        console.debug('submitting product');

        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');

        var query;
        var method;

        if (id != null) {
            console.debug('product edition');
            query = process.env.REACT_APP_API_URL + '/v1/product?id=' + id + '&name=' + name + '&price=' + price + '&quantity=' + quantity;
            method = 'PUT';
        } else {
            console.debug('product addition');
            query = process.env.REACT_APP_API_URL + '/v1/product?company_id=' + SessionService.read(SessionService.companyIdKey) + '&name=' + name + '&price=' + price + '&quantity=' + quantity;
            method = 'POST';
        }
        console.debug(query);
        fetch(query, {
            method: method,
            headers: {
                'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-saved']);
                    setError('');
                    window.location.replace('/dashboard/products');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };

    return (
        <div className='row'>
            <div className='mb-3'>
                <Translator value='i18n.name' /><Required />
                <input onChange={handleName} className='form-control' value={name} />
            </div>
            <div className='mb-3'>
                <Translator value='i18n.quantity' /><Required />
                <input onChange={handleQuantity} className='form-control' type='number' value={quantity} />
            </div>
            <div className='mb-3'>
                <Translator value='i18n.price' /><Required />
                <input onChange={handlePrice} className='form-control' type='number' value={price} />
            </div>
            <div className='mb-3'>
                <button onClick={submit} className='form-control btn btn-secondary'>
                    <Translator value='i18n.save' />
                </button>
            </div>
            {Response.html(success, error)}
        </div>
    );
}

export default FormProduct;
