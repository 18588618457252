import React from 'react';
import QueryStringService from '../../service/QueryStringService';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';
import PrintItemDTO from '../../model/PrintItemDTO';
import RowSaleItemPrint from '../row/RowSaleItemPrint';
import Translator from '../../translations/Translator';
import DateService from '../../service/DateService';

class FormSalesPrint extends React.Component {
    // @ts-ignore
    constructor() {
        // @ts-ignore
        super();
        this.state = {
            error: '',
            success: '',
            print: {
                items: [{}]
            },
            date: ''
        };
    }

    componentDidMount() {
        let id = QueryStringService.getString('id');
        var query = process.env.REACT_APP_API_URL + '/v1/sale/print?id=' + id;
        console.debug(query);
        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    this.setState({ success: '' });
                    this.setState({ error: res.error });
                } else {
                    // @ts-ignore
                    console.debug('data before :' + this.state.print);
                    this.setState({ print: res });
                    this.setState({ date: DateService.toDate(res.createdTime) });
                    // @ts-ignore
                    console.debug('data after :' + this.state.print);
                }
            })
            .catch((error) => {
                console.debug(error);
                this.setState({ error: Response.i18n['error-unknown'] });
                this.setState({ success: '' });
            });
    }

    render() {
        // @ts-ignore
        const { print, error, success, date } = this.state;
        return (
            <div id="printDiv" className="container">
                <div className="text-center">
                    <h1>
                        <Translator value="i18n.invoice" />
                    </h1>
                    <h6>{print.id}</h6>
                    <br />
                    <h5>
                        <Translator value="i18n.buyer" />: {print.customer}
                    </h5>
                    <h5>
                        <Translator value="i18n.seller" />: {print.seller}
                    </h5>
                    <h5>
                        <Translator value="i18n.date" />: {date}
                    </h5>
                    {print.phone != null && (
                        <h5>
                            <Translator value="i18n.phone" />: {print.phone}
                        </h5>
                    )}
                </div>
                <br />
                <br />
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col" className="col-3">
                                    <Translator value="i18n.product" />
                                </th>
                                <th scope="col" className="col-1">
                                    <Translator value="i18n.price" />
                                </th>
                                <th scope="col" className="col-1">
                                    <Translator value="i18n.quantity" />
                                </th>
                                <th scope="col" className="col-1">
                                    <Translator value="i18n.total.price" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {print.items.map((item: PrintItemDTO) => (
                                <RowSaleItemPrint printItem={item} />
                            ))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{print.total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {Response.html(success, error)}
            </div>
        );
    }
}

export default FormSalesPrint;
