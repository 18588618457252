import React from 'react';
import Translator from '../translations/Translator';
import {isDesktop, isTablet} from 'react-device-detect';

function DashboardLeft() {
    const navigate = (event: { target: { value: any } }) => {
        if (event.target.value != '#') {
            window.location.replace(event.target.value);
        }
    };

    if (isTablet || isDesktop) {
        return (
            <div className="col-md-3">
                <div className="list-group">
                    <a href="/dashboard/products" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.products" />
                    </a>
                    <a href="/dashboard/sales" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.sales" />
                    </a>
                    <a href="/dashboard/retailers" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.retailers" />
                    </a>
                    <a href="/dashboard/retailers/products" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.retailers.products" />
                    </a>
                    <a href="/dashboard/suppliers" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.suppliers" />
                    </a>
                    <a href="/dashboard/invitations/received" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.received.invitations" />
                    </a>
                    <a href="/dashboard/invitations/sent" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.sent.invitations" />
                    </a>
                    <a href="/dashboard/rules" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.rules" />
                    </a>
                    <a href="/dashboard/users" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.users" />
                    </a>
                    <a href="/dashboard/profile" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.profile" />
                    </a>
                    <a href="/dashboard/password" className="list-group-item list-group-item-action fs-5">
                        <Translator value="i18n.change.password" />
                    </a>
                </div>
            </div>
        );
    } else {
        return (
            <div className="col-md-12">
                <div className="mb-12">
                    <select className="form-select" onChange={navigate}>
                        <option value="#">
                            <Translator value="i18n.open.menu" />
                        </option>
                        <option value="/dashboard/products">
                            <Translator value="i18n.products" />
                        </option>
                        <option value="/dashboard/sales">
                            <Translator value="i18n.sales" />
                        </option>
                        <option value="/dashboard/retailers">
                            <Translator value="i18n.retailers" />
                        </option>
                        <option value="/dashboard/retailers/products">
                            <Translator value="i18n.retailers.products" />
                        </option>
                        <option value="/dashboard/suppliers">
                            <Translator value="i18n.suppliers" />
                        </option>
                        <option value="/dashboard/invitations/received">
                            <Translator value="i18n.received.invitations" />
                        </option>
                        <option value="/dashboard/invitations/sent">
                            <Translator value="i18n.sent.invitations" />
                        </option>
                        <option value="/dashboard/rules">
                            <Translator value="i18n.rules" />
                        </option>
                        <option value="/dashboard/users">
                            <Translator value="i18n.users" />
                        </option>
                        <option value="/dashboard/profile">
                            <Translator value="i18n.profile" />
                        </option>
                        <option value="/dashboard/password">
                            <Translator value="i18n.change.password" />
                        </option>
                    </select>
                </div>
                <hr />
            </div>
        );
    }
}

export default DashboardLeft;
