import React, {Suspense, useEffect, useState} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import TranslationService from '../service/TranslationService';
import Translator from '../translations/Translator';

function PageWriteToUs() {
    const [featureRequest, setFeatureRequest] = useState('');
    const [support, setSupport] = useState('');
    const [question, setQuestion] = useState('');
    const [mailToFeatureRequest, setMailToFeatureRequest] = useState('');
    const [mailToSupport, setMailToSupport] = useState('');
    const [mailToQuestion, setMailToQuestion] = useState('');

    useEffect(() => {
        setFeatureRequest(TranslationService.translate('i18n.feature.request'));
        setSupport(TranslationService.translate('i18n.support'));
        setQuestion(TranslationService.translate('i18n.question'));
        setMailToFeatureRequest('mailto:contact@zizier.com?subject=zizier.com - ' + TranslationService.translate('i18n.feature.request'));
        setMailToQuestion('mailto:contact@zizier.com?subject=zizier.com - ' + TranslationService.translate('i18n.support'));
        setMailToSupport('mailto:contact@zizier.com?subject=zizier.com - ' + TranslationService.translate('i18n.support'));
    }, []);
    document.title = 'Write to us - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <a className="row no-decoration" href={mailToFeatureRequest}>
                        <button className="form-control btn btn-secondary btn-lg">{featureRequest}</button>
                    </a>
                    <br />

                    <a className="row no-decoration" href={mailToSupport}>
                        <button className="form-control btn btn-secondary btn-lg">{support}</button>
                    </a>
                    <br />
                    <a className="row no-decoration" href={mailToQuestion}>
                        <button className="form-control btn btn-secondary btn-lg">{question}</button>
                    </a>
                    <br />
                    <a className="row no-decoration" target="_blank" href="https://calendly.com/zizier/30min">
                        <button className="form-control btn btn-secondary btn-lg">{TranslationService.translate('i18n.request.demo')}</button>
                    </a>
                </div>
                <div className="col-md-4"></div>

                <Footer />
            </div>
        </Suspense>
    );
}

export default PageWriteToUs;
