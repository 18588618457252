class CompanyDTO {
    id!: string;
    createdTime!: string;
    name!: string;
    contactNumbers!: string[];
    receiptNumber!: string;
    address!: string;
    description!: string;
    retailer!: boolean;
    supplier!: boolean;
}

export default CompanyDTO;
