import React, {useEffect, useState} from 'react';
import ButtonDelete from '../button/ButtonDelete';
import RuleDTO from '../../model/RuleDTO';
import ButtonEdit from '../button/ButtonEdit';

function RowRule(props: { rule: RuleDTO }) {
    // @ts-ignore
    const [parameters, setParameters] = useState('');
    const [subscriptions, setSubscriptions] = useState('');
    var url = '/dashboard/rules/form?id=' + props.rule.id + '&target=' + props.rule.targetCompanyId;

    useEffect(() => {
        // @ts-ignore
        setSubscriptions(props.rule.subscriptions.map(s => s.value + '\n'));
        // @ts-ignore
        let quantity = props.rule.parameters['QUANTITY'];
        // @ts-ignore
        let product_id = props.rule.parameters['PRODUCT_ID'];
        if (product_id == null) {
            setParameters(quantity);
        } else {
            setParameters(quantity + '\n\n' + props.rule.productName);
        }

    }, []);
    return (
        <tr>
            <td>{props.rule.name}</td>
            <td>{props.rule.targetCompanyName}</td>
            <td>
                {subscriptions}
            </td>
            <td>
                {parameters}
            </td>
            <td>
                <ButtonEdit action={url} />
            </td>
            <td>
                <ButtonDelete id={props.rule.id} type='rule' />
            </td>
        </tr>
    );
}

export default RowRule;
