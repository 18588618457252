import React, {useEffect, useState} from 'react';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';
import Translator from '../../translations/Translator';
import Required from '../../shared/Required';
import QueryStringService from '../../service/QueryStringService';

function FormRule() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleRole = (event: { target: { value: any } }) => {
        setRole(event.target.value);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        if (id != null) {
            console.debug('getting user');
            var query;
            query = process.env.REACT_APP_API_URL + '/v1/user?id=' + id;
            console.debug(query);
            fetch(query, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
                }
            })
                .then((res) => res.json())
                .then((res) => {
                    console.debug(res);
                    if (res.hasOwnProperty('error')) {
                        setSuccess('');
                        setError(res.error + Response.support + res.id);
                    } else {
                        setName(res.name);
                        setEmail(res.email);
                        setRole(res.roles[0]);
                    }
                })
                .catch((error) => {
                    console.debug(error);
                    setError(Response.i18n['error-unknown']);
                    setSuccess('');
                });
        }
    }, []);


    const save = () => {
        let id = QueryStringService.getString('id');
        var query = '';
        console.debug('updating user');
        query = process.env.REACT_APP_API_URL + '/v1/user?id=' + id + '&name=' + name + '&role=' + role;
        console.debug(query);
        fetch(query, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['success-saved']);
                    setError('');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };

    return (
        <div className='row'>
            <div className='mb-3'>
                <Translator value='i18n.name' /> :
                <input className='form-control' value={name} disabled />
            </div>
            <div className='mb-3'>
                <Translator value='i18n.email' /> :
                <input className='form-control' value={email} disabled />
            </div>
            <div className='mb-3'>
                <Translator value='i18n.role' /><Required />
                <div className='col-sm'>
                    <select className='form-select' value={role} onChange={handleRole}>
                        <option value='ADMIN'><Translator value='i18n.role.admin' /></option>
                        <option value='SELLER'><Translator value='i18n.role.seller' /></option>
                    </select>
                </div>
            </div>
            <div className='row'>
                <button className='btn btn-secondary' onClick={save}>
                    <Translator value='i18n.save' />
                </button>
            </div>
            <br />
            {Response.html(success, error)}
        </div>
    );
}

export default FormRule;
