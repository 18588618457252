import React from 'react';
import messages_fr from '../translations/fr.json';
import messages_en from '../translations/en.json';

class TranslationService {
    public static translate(value: string) {
        var messages;
        const language = navigator.language.split(/[-_]/)[0];
        if (language === 'fr') {
            messages = messages_fr;
        } else {
            messages = messages_en;
        }
        // @ts-ignore
        return messages[value];
    }
}

export default TranslationService;
