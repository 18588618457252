import React, {useEffect, useState} from 'react';
import ButtonDelete from '../button/ButtonDelete';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';
import QueryStringService from '../../service/QueryStringService';
import IdNameDTO from '../../model/IdNameDTO';
import SubscriptionDTO from '../../model/SubscriptionDTO';
import Translator from '../../translations/Translator';
import Required from '../../shared/Required';

function FormRule() {

    const [products, setProducts] = useState<IdNameDTO[]>([]);
    const [targets, setTargets] = useState<IdNameDTO[]>([]);
    const [subscriptions, setSubscriptions] = useState<SubscriptionDTO[]>([]);
    const [subscription, setSubscription] = useState('');
    const [productId, setProductId] = useState('');
    const [targetId, setTargetId] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleProductId = (event: { target: { value: any } }) => {
        setProductId(event.target.value);
    };

    const handleTargetId = (event: { target: { value: any } }) => {
        let id = QueryStringService.getString('id');
        if (id == null) {
            window.location.replace('/dashboard/rules/form?target=' + event.target.value);
        } else {
            window.location.replace('/dashboard/rules/form?id=' + id + '&target=' + event.target.value);
        }
    };

    const handleQuantity = (event: { target: { value: any } }) => {
        setQuantity(event.target.value);
    };

    const handleName = (event: { target: { value: any } }) => {
        setName(event.target.value);
    };

    const handleSubscription = (event: { target: { value: any } }) => {
        setSubscription(event.target.value);
    };

    useEffect(() => {
        console.debug('getting all the target companys');
        var query;
        query = process.env.REACT_APP_API_URL + '/v1/company/targets?company_id=' + SessionService.read(SessionService.companyIdKey);
        console.debug(query);
        fetch(query, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setTargets(res);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });

        refreshProducts();

        let id = QueryStringService.getString('id');
        if (id != null) {
            // fetching data
            console.debug('fetching data for ' + id);
            query = process.env.REACT_APP_API_URL + '/v1/rule?id=' + id;
            console.debug(query);
            fetch(query, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
                }
            })
                .then((res) => res.json())
                .then((res) => {
                    console.debug(res);
                    if (res.hasOwnProperty('error')) {
                        setSuccess('');
                        setError(res.error + Response.support + res.id);
                    } else {
                        setName(res.name);
                        setQuantity(res.parameters['QUANTITY']);
                        setProductId(res.parameters['PRODUCT_ID']);
                        //setTargetId(res.targetCompanyId);
                        setSubscriptions(res.subscriptions);
                    }
                })
                .catch((error) => {
                    console.debug(error);
                    setError(Response.i18n['error-unknown']);
                    setSuccess('');
                });
        }
    }, []);


    const refreshProducts = () => {

        let target_company_id = QueryStringService.getString('target');
        if (target_company_id != null) {
            setTargetId(target_company_id);
        }
        console.debug('getting all the target products');
        var query;
        query = process.env.REACT_APP_API_URL + '/v1/product/all/select?company_id=' + target_company_id;
        console.debug(query);
        fetch(query, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setProducts(res);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });

    };

    const saveRule = () => {
        // @ts-ignore
        let id = QueryStringService.getString('id');
        var query = '';
        var method = '';
        let target_company_id = QueryStringService.getString('target');

        if (id == null) {
            console.debug('adding rule');
            method = 'POST';
            query = process.env.REACT_APP_API_URL + '/v1/rule?company_id=' + SessionService.read(SessionService.companyIdKey) + '&target_company_id=' + target_company_id + '&name=' + name + '&type=EMAIL&value=' + subscription;
        } else {
            console.debug('updating rule');
            method = 'PUT';
            query = process.env.REACT_APP_API_URL + '/v1/rule?id=' + id + '&target_company_id=' + target_company_id + '&name=' + name + '&type=EMAIL&value=' + subscription;
        }

        var body;
        if (productId == '') {
            body = JSON.stringify({ QUANTITY: quantity });
        } else {
            body = JSON.stringify({ QUANTITY: quantity, PRODUCT_ID: productId });
        }

        console.debug(query);
        console.debug(body);

        fetch(query, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
            },
            body: body
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    window.location.replace('/dashboard/rules/form?id=' + res.id + '&target=' + target_company_id);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };

    // @ts-ignore
    // @ts-ignore
    return (
        <div className='row'>
            <div className='mb-3'>
                <Translator value='i18n.name' /><Required />
                <input onChange={handleName} className='form-control' value={name} />
            </div>
            <div className='mb-3'>
                <Translator value='i18n.quantity' /><Required />
                <input onChange={handleQuantity} type='number' className='form-control' value={quantity} />
            </div>
            <div className='mb-3'>
                <Translator value='i18n.target' /><Required />
                <div className='col-sm'>
                    <select className='form-select' value={targetId} onChange={handleTargetId}>
                        {targets.map(item =>
                            <option value={item.id}>{item.name}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className='mb-3'>
                <Translator value='i18n.product.2' />
                <div className='col-sm'>
                    <select className='form-select' value={productId} onChange={handleProductId}>
                        <option value=''>None</option>
                        {products.map(item => <option value={item.id}>{item.name}</option>)}
                    </select>
                </div>
            </div>
            <div className='mb-3'>
                <Translator value='i18n.emails.to.notify' /><Required />
                {subscriptions.map(item =>
                    <div>
                        <div className='row'>
                        <div className='col-sm-9'>
                            <input className='form-control' value={item.value} disabled />
                        </div>
                        <div className='col-sm-3'>
                            <ButtonDelete id={item.id} type='subscription' />
                        </div>
                        </div>
                        <br />
                    </div>
                )}
                <div className='row'>
                    <div className='col-sm-9'>
                        <input className='form-control' onChange={handleSubscription} />
                    </div>
                    <div className='col-sm-3 row'>
                        <button className='btn btn-secondary' onClick={saveRule}>
                            <Translator value='i18n.save' />
                        </button>
                    </div>
                </div>
            </div>
            {Response.html(success, error)}
        </div>
    );
}

export default FormRule;
