import React from 'react';
import InvitationDTO from '../../model/InvitationDTO';
import ButtonNavGray from '../button/ButtonNavGray';
import DateService from '../../service/DateService';

function RowInvitationReceived(props: { invitation: InvitationDTO }) {
    var url = '/dashboard/company?id=' + props.invitation.senderCompanyId;
    var replyUrl = '/invite/supplier/reply?token=' + props.invitation.token + '&company=' + props.invitation.senderCompanyName;
    return (
        <tr>
            <td>{DateService.toDate(props.invitation.createdTime)}</td>
            <td>{props.invitation.senderCompanyName}</td>
            <td>
                <ButtonNavGray value="i18n.view" action={url} />
            </td>
            <td>
                <ButtonNavGray value="i18n.reply" action={replyUrl} />
            </td>
        </tr>
    );
}

export default RowInvitationReceived;
