import React from 'react';
import ButtonEdit from '../button/ButtonEdit';
import ButtonDelete from '../button/ButtonDelete';
import SaleDTO from '../../model/SaleDTO';
import ButtonNavGray from '../button/ButtonNavGray';
import DateService from '../../service/DateService';

function RowSale(props: { sale: SaleDTO }) {
    var editUrl = '/dashboard/sales/form?id=' + props.sale.id;
    var printUrl = '/dashboard/sales/print?id=' + props.sale.id;
    return (
        <tr>
            <td>{DateService.toDate(props.sale.createdTime)}</td>
            <td>{props.sale.seller}</td>
            <td>{props.sale.customer}</td>
            <td>
                <ButtonEdit action={editUrl} />
            </td>
            <td>
                <ButtonNavGray value='i18n.print' action={printUrl} />
            </td>
            <td>
                <ButtonDelete id={props.sale.id} type='sale' />
            </td>
        </tr>

    );
}

export default RowSale;
