import React from 'react';
import ButtonDelete from '../button/ButtonDelete';
import CompanyDTO from '../../model/CompanyDTO';
import ButtonNavGray from '../button/ButtonNavGray';

function RowCompany(props: { company: CompanyDTO; type: string }) {
    var url = '/dashboard/company?id=' + props.company.id;
    return (
        <tr>
            <td>{props.company.id}</td>
            <td>{props.company.name}</td>
            <td>{props.company.contactNumbers}</td>
            <td>
                <ButtonNavGray value="i18n.view" action={url} />
            </td>
            <td>
                <ButtonDelete id={props.company.id} type={props.type} />
            </td>
        </tr>
    );
}

export default RowCompany;
