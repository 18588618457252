import React, {Suspense, useEffect, useState} from 'react';
import DashboardLeft from '../../shared/DashboardLeft';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import CompanyDTO from '../../model/CompanyDTO';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';
import QueryStringService from '../../service/QueryStringService';
import Translator from '../../translations/Translator';
import Line from '../../shared/Line';

function PageCompany() {
    const [company, setCompany] = useState<CompanyDTO>(new CompanyDTO());
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [numbers, setNumbers] = useState<string[]>([' ']);

    useEffect(() => {
        let id = QueryStringService.getString('id');
        var query = process.env.REACT_APP_API_URL + '/v1/company?id=' + id;
        console.debug(query);
        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    // @ts-ignore
                    console.debug('data before :' + company);
                    setCompany(res);
                    if (company.contactNumbers != null && company.contactNumbers != []) {
                        setNumbers(company.contactNumbers);
                    }
                    // @ts-ignore
                    console.debug('data after :' + company);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    }, []);
    document.title = 'Companies list - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <DashboardLeft />
                <div className="col-md-9">
                    <h5>
                        <Translator value="i18n.company.view" />
                    </h5>
                    <Line />
                    <div className="container">
                        <div className="mb-3 row">
                            <label className="col-sm-3 form-label">
                                <Translator value="i18n.code" /> :
                            </label>
                            <div className="col-sm-9">
                                <input className="form-control" value={company.id} disabled></input>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-3 form-label">
                                <Translator value="i18n.name" /> :
                            </label>
                            <div className="col-sm-9">
                                <input className="form-control" value={company.name} disabled></input>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-3 form-label">
                                <Translator value="i18n.contact.numbers" /> :
                            </label>
                            <div className="col-sm-9">
                                {numbers.map((item) => (
                                    <div>
                                        <input className="form-control" value={item} disabled></input>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-3 form-label">
                                <Translator value="i18n.address" /> :
                            </label>
                            <div className="col-sm-9">
                                <input className="form-control" value={company.address} disabled></input>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-3 form-label">
                                <Translator value="i18n.description" /> :
                            </label>
                            <div className="col-sm-9">
                                <textarea className="form-control" rows={5} value={company.address} disabled></textarea>
                            </div>
                        </div>
                        {Response.html(success, error)}
                    </div>
                </div>
                <Footer />
            </div>
        </Suspense>
    );
}

export default PageCompany;
