import React from 'react';
import ButtonEdit from '../button/ButtonEdit';
import ButtonDelete from '../button/ButtonDelete';
import ProductDTO from '../../model/ProductDTO';

function RowProduct(props: { product: ProductDTO }) {
    var editUrl = '/dashboard/products/form?id=' + props.product.id;
    return (
        <tr>
            <td>{props.product.name}</td>
            <td>{props.product.quantity}</td>
            <td>{props.product.price}</td>
            <td>
                <ButtonEdit action={editUrl} />
            </td>
            <td>
                <ButtonDelete id={props.product.id} type='product' />
            </td>
        </tr>
    );
}

export default RowProduct;
