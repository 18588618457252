import React from 'react';
import JwtDTO from '../model/JwtDTO';

class SessionService {
    public static accessKey = 'access';
    public static refreshKey = 'refresh';
    public static companyIdKey = 'companyId';
    public static emailKey = 'email';
    public static languageKey = 'language';
    public static nameKey = 'name';
    public static permissionsKey = 'permissions';
    public static rolesKey = 'roles';
    public static userIdKey = 'userId';
    public static trialDaysLeft = 'trialDaysLeft';
    public static loggedInTime = 'loggedInTime';

    public static login(jwtDTO: JwtDTO) {
        if (typeof jwtDTO.access === 'string') {
            localStorage.setItem(this.accessKey, jwtDTO.access);
        }
        if (typeof jwtDTO.refresh === 'string') {
            localStorage.setItem(this.refreshKey, jwtDTO.refresh);
        }
        // @ts-ignore
        localStorage.setItem(this.companyIdKey, jwtDTO.jwtTokenDTO.companyId);
        // @ts-ignore
        localStorage.setItem(this.emailKey, jwtDTO.jwtTokenDTO.email);
        // @ts-ignore
        localStorage.setItem(this.languageKey, jwtDTO.jwtTokenDTO.language);
        // @ts-ignore
        localStorage.setItem(this.nameKey, jwtDTO.jwtTokenDTO.name);
        // @ts-ignore
        localStorage.setItem(this.permissionsKey, jwtDTO.jwtTokenDTO.permissions);
        // @ts-ignore
        localStorage.setItem(this.rolesKey, jwtDTO.jwtTokenDTO.roles);
        // @ts-ignore
        localStorage.setItem(this.userIdKey, jwtDTO.jwtTokenDTO.userId);
        // @ts-ignore
        localStorage.setItem(this.trialDaysLeft, jwtDTO.jwtTokenDTO.trialDaysLeft);
        localStorage.setItem(this.loggedInTime, String(Date.now()));
    }

    public static isLoggedIn() {
        return localStorage.getItem(this.nameKey) != null;
    }

    public static logout() {
        localStorage.clear();
    }

    public static read(key: string) {
        let loggedInTimeValue = Number(localStorage.getItem(this.loggedInTime));
        if (loggedInTimeValue != null) {
            let currentTimeValue = Date.now();
            let difference = currentTimeValue - loggedInTimeValue;
            let maxTime = 1000 * 60 * 60 * 24;
            if (difference < maxTime) {
                console.debug('Still in the clear, only ' + difference + ' milliseconds out of ' + maxTime + ' have passed');
                return localStorage.getItem(key);
            } else {
                console.debug('Abort everything, more than ' + maxTime + ' milliseconds have passed. Currently at ' + difference + ' milliseconds');
                this.logout();
                window.location.replace('/');
            }
        } else {
            console.debug('No loggedInTime');
        }
        return null;
    }
}

export default SessionService;
