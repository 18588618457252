import React, {Suspense} from 'react';
import DashboardLeft from '../../shared/DashboardLeft';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import FormSalesPrint from '../form/FormSalesPrint';
import ReactToPrint from 'react-to-print';
import ButtonFormBlue from '../button/ButtonFormBlue';
import Translator from '../../translations/Translator';
import Line from '../../shared/Line';

class PageSalesPrint extends React.Component {
    componentRef: any;

    render() {
        document.title = 'Sale print - zizier';
        return (
            <Suspense
                fallback={
                    <p>
                        <Translator value="i18n.loading" />
                    </p>
                }
            >
                <div className="row">
                    <Header />
                    <DashboardLeft />
                    <div className="col-md-9">
                        <h5>
                            <Translator value="i18n.print.sale" />
                        </h5>
                        <Line />
                        <ReactToPrint
                            trigger={() => (
                                <a>
                                    <ButtonFormBlue value="i18n.print" />
                                </a>
                            )}
                            content={() => this.componentRef}
                        />
                        <FormSalesPrint ref={(el) => (this.componentRef = el)} />
                    </div>
                    <Footer />
                </div>
            </Suspense>
        );
    }
}

export default PageSalesPrint;
