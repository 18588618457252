import React, {useEffect, useState} from 'react';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';
import QueryStringService from '../../service/QueryStringService';
import IdNameDTO from '../../model/IdNameDTO';
import Translator from '../../translations/Translator';
import SaleItemDTO from '../../model/SaleItemDTO';
import RowSaleItem from '../row/RowSaleItem';
import Required from '../../shared/Required';

function FormSale() {

    const [products, setProducts] = useState<IdNameDTO[]>([]);
    const [items, setItems] = useState<SaleItemDTO[]>([]);
    const [customer, setCustomer] = useState('');
    const [productId, setProductId] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleCustomer = (event: { target: { value: any } }) => {
        setCustomer(event.target.value);
    };

    const handleProductId = (event: { target: { value: any } }) => {
        setProductId(event.target.value);
    };

    const handleQuantity = (event: { target: { value: any } }) => {
        setQuantity(event.target.value);
    };


    useEffect(() => {

        console.debug('getting all the products');
        var query;
        query = process.env.REACT_APP_API_URL + '/v1/product/all/select?company_id=' + SessionService.read(SessionService.companyIdKey);
        console.debug(query);
        fetch(query, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setProducts(res);
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });

        let id = QueryStringService.getString('id');
        if (id != null) {
            // fetching data
            console.debug('fetching data for ' + id);
            query = process.env.REACT_APP_API_URL + '/v1/sale?id=' + id;
            console.debug(query);
            fetch(query, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
                }
            })
                .then((res) => res.json())
                .then((res) => {
                    console.debug(res);
                    if (res.hasOwnProperty('error')) {
                        setSuccess('');
                        setError(res.error + Response.support + res.id);
                    } else {
                        setCustomer(res.customer);
                        setItems(res.items);
                    }
                })
                .catch((error) => {
                    console.debug(error);
                    setError(Response.i18n['error-unknown']);
                    setSuccess('');
                });
        }
    }, []);


    const saveSale = () => {
        let id = QueryStringService.getString('id');
        var query = '';
        if (id == null) {
            console.debug('adding sale');
            query = process.env.REACT_APP_API_URL + '/v1/sale?company_id=' + SessionService.read(SessionService.companyIdKey) + '&customer=' + customer + '&product_id=' + productId + '&quantity=' + quantity;
        } else {
            console.debug('updating sale');
            query = process.env.REACT_APP_API_URL + '/v1/sale/item?sale_id=' + id + '&customer_name=' + customer + '&product_id=' + productId + '&quantity=' + quantity;
        }

        console.debug(query);
        fetch(query, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + SessionService.read(SessionService.accessKey)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    if (id == null) {
                        window.location.replace('/dashboard/sales/form?id=' + res.id);
                    } else {
                        window.location.replace('/dashboard/sales/form?id=' + res.saleId);
                    }
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };

    return (
        <div className='row'>
            <div className='mb-3'>
                <Translator value='i18n.customer' /><Required />
                <input onChange={handleCustomer} className='form-control' value={customer} />
            </div>
            <div className='mb-3'>
                {items.map(item => <RowSaleItem saleItem={item} />)}
                <div className='row'>
                    <div className='col-sm-6'>
                        <select className='form-select' onChange={handleProductId}>
                            <option selected>
                                <Translator value='i18n.select.a.product' />
                            </option>
                            {products.map(item => <option value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                    <div className='col-sm-4'>
                        <input onChange={handleQuantity} type='number' className='form-control'
                               placeholder='Quantity' />
                    </div>
                </div>
                <br />
                <div className='row'>
                    <button className='btn btn-secondary' onClick={saveSale}>
                        <Translator value='i18n.save' />
                    </button>
                </div>

            </div>
            {Response.html(success, error)}
        </div>
    );
}

export default FormSale;
