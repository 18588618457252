import React from 'react';

class DateService {
    public static toDate(unformattedDate: string) {
        unformattedDate = unformattedDate.replaceAll('T', ' ');
        unformattedDate = unformattedDate.split('.')[0];
        return unformattedDate;
    }
}

export default DateService;
