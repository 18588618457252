import React from 'react';
import UserDTO from '../../model/UserDTO';
import RowUser from '../row/RowUser';
import Translator from '../../translations/Translator';

function TableUsers(props: { data: Array<UserDTO> }) {
    // @ts-ignore
    return (
        <table className="table table-striped table-hover table-dark table-sm table-responsive-sm">
            <thead>
                <th scope="col" className="col-5">
                    <Translator value="i18n.name" />
                </th>
                <th scope="col" className="col-5">
                    <Translator value="i18n.email" />
                </th>
                <th scope="col" className="col-1"></th>
                <th scope="col" className="col-1"></th>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowUser user={item} />
                ))}
            </tbody>
        </table>
    );
}

export default TableUsers;
