import React from 'react';

class QueryStringService {
    public static getString(key: string) {
        const queryParams = new URLSearchParams(window.location.search);
        let res: string | null = queryParams.get(key);
        if (res != null) {
            console.debug('Found key ' + key + ' and value ' + res);
        }
        return res;
    }

    public static getNumber(key: string) {
        const queryParams = new URLSearchParams(window.location.search);
        let res: string | null = queryParams.get(key);
        if (res == null) {
            res = '0';
        } else {
            let value = parseInt(res);
            value = value - 1;
            res = value + '';
        }
        return res;
    }
}

export default QueryStringService;
