import React from 'react';
import InvitationDTO from '../../model/InvitationDTO';
import RowInvitationSent from '../row/RowInvitationSent';
import Translator from '../../translations/Translator';

function TableInvitationSent(props: { data: Array<InvitationDTO> }) {
    // @ts-ignore
    return (
        <table className="table table-striped table-hover table-dark table-sm table-responsive-sm">
            <thead>
                <tr>
                    <th scope="col" className="col-4">
                        <Translator value="i18n.date" />
                    </th>
                    <th scope="col" className="col-7">
                        <Translator value="i18n.email" />
                    </th>
                    <th scope="col" className="col-1"></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowInvitationSent invitation={item} />
                ))}
            </tbody>
        </table>
    );
}

export default TableInvitationSent;
