import React, {Suspense, useState} from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Response from '../model/Response';
import Translator from '../translations/Translator';
import Required from '../shared/Required';

function PageRecover() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleEmail = (event: { target: { value: any } }) => {
        setEmail(event.target.value);
    };

    const register = () => {
        console.debug('recovering with ' + email);
        var query = process.env.REACT_APP_API_URL + '/v1/user/password/recover/1?email=' + email;
        console.debug(query);
        fetch(query, {
            method: 'POST'
        })
            .then((res) => res.json())
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    setSuccess('');
                    setError(res.error + Response.support + res.id);
                } else {
                    setSuccess(Response.i18n['recover-register']);
                    setError('');
                }
            })
            .catch((error) => {
                console.debug(error);
                setError(Response.i18n['error-unknown']);
                setSuccess('');
            });
    };
    document.title = 'Recover password - zizier';
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <Translator value="i18n.email.address" />
                        <Required />
                        <input type="email" onChange={handleEmail} className="form-control form-control-lg" name="email" id="signupSimpleLoginEmail" aria-label="email@site.com" />
                    </div>

                    <div className="d-grid mb-3">
                        <button onClick={register} className="form-control btn btn-secondary btn-lg">
                            <Translator value="i18n.recover" />
                        </button>
                    </div>
                    {Response.html(success, error)}
                </div>
                <div className="col-md-4"></div>

                <Footer />
            </div>
        </Suspense>
    );
}

export default PageRecover;
